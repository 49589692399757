@require "../core/variables";

.correspondence-icon
	width 12px
	height 12px
	top 5px
	background-position center
	background-size 50%
	background-repeat no-repeat
	margin 0
	display inline-block
	&.arrow-right
		background-image url("../../img/icons/chevron-right.svg")

.caret {
	&.caret-right {
		float: right;
		position: absolute;
		right: 5px;
		top: 14px;
		border-left: 4px dashed;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
	}
}

/* Z-Index Styling to keep tooltip and dropdown working in and outside of modals */
.tooltip {
	z-index: 1100;
	.hide-tooltips & {
		visibility: hidden !important;
	}
}

  /* Z-Index Styling to keep datepicker-popups above modal dialogs when using datepicker-append-to-body="true" */
body > div > ul.uib-datepicker-popup {
  z-index: 2000;
}

.dropdown-menu {
	z-index: 1000;
	.modal & {
		z-index: 2000;
	}
    .selectable {
	  .btn.btn-default.btn-sm {
		background: #a2db89;
	  }
	}
	.selectableWithWarnings {
	  .btn.btn-default.btn-sm {
		background: #e8e2a1 !important;
	  }
	}
	.btn.btn-default {
		&.btn-info,
		&.btn-sm {
			&.active {
				background: #addfe9 !important;
			}
		}
	}
	li {
	  > button {
		border: 0;
		width: 100%;
		padding: 3px 20px;
		text-align: left;
		background: #fff;
		font-weight: normal;
		&:hover {
			color: #303030;
			background-color: #f5f5f5;
		}
	  }
	  &[disabled] {
		> button {
		  color: #777;
		  cursor: not-allowed;
		}
	  }
    }
}

.row.iframe-container {
  	margin: 0;
	.loading-spinner {
		position: absolute;
		top: 26vh;
		left: 49vw;

		border-radius: 1.5em;
		box-shadow: rgba(0, 0, 51, 0.3) 3em 0 0 0, rgba(0, 0, 51, 0.3) 2.2em 2.2em 0 0, rgba(0, 0, 51, 0.3) 0 3em 0 0, rgba(0, 0, 51, 0.3) -2.2em 2.2em 0 0, rgba(0, 0, 51, 0.3) -3em 0 0 0, rgba(0, 0, 51, 0.3) -2.2em -2.2em 0 0, rgba(0, 0, 51, 0.3) 0 -3em 0 0, rgba(0, 0, 51, 0.3) 2.2em -2.2em 0 0;
		width: 2em;
		height: 2em;
	}
	iframe {
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.post-control-label {
	float: left;
	text-align: left;
	font-weight: normal;
	padding-left: 10px !important;
	padding-top: 7px;
	position: relative;
	.post-control-label-icon {
		background-image: url("../../img/icons/Address-Book2.svg");
		width: 20px;
		height: 20px;
		display: block;
		background-size: 100%;
		cursor: pointer;
	}
}

.input-group {
  .input-group-addon {
	.post-control-label {
	  padding-left: 0px !important;
	  padding-top: 0px;
	}
  }
}

.page-header {
	margin: 0;
	padding: 0;

	.ui-select-header {
		> label {
			vertical-align: middle;
		}
		.control-label {
			margin: 0 10px 0 0;
			height: 35px;
			display: block;
			float: left;
			padding: 7px 0;
		}
		> div {
			display: inline-block;
			vertical-align: middle;
			min-width: 250px;

			> .ui-select-match {
				> span {
					padding: 1px 10px;
				}
			}

			> .form-control {
				border-radius: 0;
			}
		}
	}
}

.btn-container {
	display: inline-block;
	.btn-group {
		float: left;
	  margin: 0 7px 5px 0;
	  .btn {
		border-left: 1px solid #c9c9c9;
		+ .btn {
		  margin-left: 0;
		}
		&:first-child {
		  border-left: 0;
		}

		&.btn-primary {
		  + .btn-primary {
			&.btn-additional {
			  border-left: 1px solid #34911f;
			}
		  }
		}
	  }
	  &.open > .dropdown-toggle.btn-primary {
		background-color: #155758;
		border-bottom: 3px solid #0b3233;
	  }
	}
	.form-group {
		float: left;
		margin: 0;
		min-height: 40px;
		padding: 2px 5px 5px 0;
		label {
			float: left;
			margin: 4px 5px 0 10px;
		}
		.ui-select-container {
			float: left;
			width: 300px;
		}
	}
}

.table {
	thead {
		background-color: #deeff3;
		.table-header {
			&.table-select-all {
				td {
					text-align center
					background-color #f3f0cd
					padding: 2px 20px
					font-weight: bold
				}
			}
		}
	}
	tbody {
		.table-subheader {
			background: #f3f0cd;
		}
	}
	thead, tbody, tfoot {
		tr {
			a {
				color: $default-text-color;
				&.secondary-link {
					color: #3782c4;
					cursor: pointer;
				}
			}
			th {
				border-bottom: 0;
				padding: 5px 10px;
				background-color: #e8e2a1;
			}
			td {
				padding: 10px;
			}
			th, td {
				border-top: 0;
				border-bottom: 1px solid #ddd;
				&.text-right,
				&.text-right-portrait {
					text-align: right;
				}
				&.showOnTablet,
				&.showOnVeryLargeDesktop,
				&.showOnDesktop
				&.showOnLargeDesktop {
					display: none;
				}
			  	input[type="radio"],
			  	input[type="checkbox"] {
					float: left;
				}
			}
			&.toolbar {
				th {
					background: #dceff3;
					padding: 10px 20px;
					vertical-align: middle;
					&:first-child {
						width: 58px;
					}
					.btn-container {
						float: left;
						.btn-group {
							margin-right: 7px;
							margin-bottom: 0;
							.btn + .btn {
								margin-left: 0;
							}
						}
					}
					&:hover {
						th {
							background: #deeff3;
						}
						.dropdown {
							display: inline-block;
						}
					}
				}
			}
			&.active {
				td {
					background-color: $item-selected-color;
				}
			}
		}
	}
	&.table-selectable {
		tr {
			th, td {
				&:first-child {
					border-right: 1px solid #dedede;
				}
			}
		}
	}
	&.table-hover {
		> tbody {
			> tr:hover {
				td {
					background-color: $item-hover-color;
				}
			}
		}
	}
	&.table-special-hover {
	  > tbody {
		> tr:hover:not(.disableHover) {
		  td {
			background-color: $item-hover-color;
		  }
		}
	  }
	}
	&.table-click {
		tr {
			td {
				cursor: pointer;
			}
			&.meds-header-02,
			&.meds-header-03,
			&.table-data-header {
				td {
					cursor: default;
				  	position: static;
				}
			}
			&:hover {
				td {
					a.primary-link {
						text-decoration: underline;
					}
					&:first-child {
						a {
							text-decoration: underline;
						}
						td {
							a {
								text-decoration: none;
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
	}
	&.table-sort {
		> thead {
			tr {
				th {
					cursor: pointer;
					&.has-sort:hover {
						background: #e0d990;
					}
					.sort-up,
					.sort-down {
						width: 0;
						height: 0;
						position: relative;
						top: 0;
						left: 5px;
						border-right: 4px solid transparent;
						border-left: 4px solid transparent;
						display: inline-block;
					}
					.sort-up {
						border-top: 0 solid transparent;
						border-bottom: 7px solid #000;
					}
					.sort-down {
						border-top: 7px solid #000;
						border-bottom: 0 solid transparent;
					}
				}
			}
		}
	}
	&.table-mobile-optimized {
		> thead,
		> tbody {
			+ tbody {
				border-top: 0;
			}
			tr {
				th,
				td {
					background-clip: padding-box !important;
					padding: 5px 0;
					input[type="checkbox"] {
						margin-top: 5px;
						margin-right: 0;
					}
					table {
						width: 100%;
						table-layout: fixed;
						> tbody {
							tr {
								td {
									display: block;
									border: 0;
									vertical-align: top;
									padding: 0 5px 0 70px;
									&:first-child {
										padding-left: 40px;
									}
									.child-toggle {
										background-image: url("../../img/icons/chevron-right.svg");
										background-repeat: no-repeat;
										background-size: 9px 18px;
										background-position: center center;
										width: 40px;
										height: 40px;
										position: absolute;
										top: -7px;
										left: 0;
										float: left;
										&.child-shown {
											background-image: url("../../img/icons/chevron-down.svg");
											background-size: 18px 9px;
										}
									}
									span {
										> span,
										> small {
											position: relative;
											top: -4px;
										}
										> a {
											+ span {
												top: 0;
											}
										}
										.icon {
											width: 19px;
											height: 19px;
											top: 5px;
											background-position: center;
											background-size: 45%;
											margin: 0;
											&.arrow-right {
												background-image: url("../../img/icons/chevron-right.svg");
											}
										}
										small {
											margin-left: 5px;
											font-size: 14px;
											color: #9e9e9e;
											span {
												top: 0;
											}
										}
									}
									[class*="-icon"] {
										+ span {
											a {
												position: relative;
												top: -4px;
											}
											small {
											  	a {
												  	top: 0;
												    color: #337ab7;
												}
											}
										}
									}
									.citizens-container {
										+ table {
											td {
												&:last-child {
													text-align: left;
													padding-left: 0;
												}
											}
										}
									}
									.toggle-citizens {
										top: 0;
										span {
											top: 0;
										}
										.hide-citizens {
											display: none;
										}
										&:hover {
											text-decoration: underline;
										}
									}
									&:first-child {
										border-right: 0;
									}
									&.shown {
										.toggle-citizens {
											.show-citizens {
												display: none;
											}
											.hide-citizens {
												display: inline;
											}
										}
										.citizens-container {
											display: block;
										}
									}
									table {
										tbody {
											tr {
												td {
													padding: 0 !important;
													&:first-child {
														text-align: left;
													}
												}
											}
										}
									}
								}
								&:hover td a.primary-link {
									text-decoration: underline;
								}
							}
						}
					}
					&.table-cell-empty {
						display: none;
					}
				}
				&.child-node {
					table {
						td {
							padding-left: 100px;
							&:first-child {
								padding-left: 70px;
							}
							.child-toggle {
								@media not all and (min-width: $screen-md-min) {
									left: 30px;
								}
							}
						}
					}
					&.child-level-2 {
						table td {
							@media not all and (min-width: $screen-md-min) {
								padding-left: 130px;
							}
							&:first-child {
								padding-left: 100px;
							}
						}
					}
				}
				&:hover {
					td {
						background: $item-hover-color;
					}
				}
				&.table-header {
					td {
						background-color: #f3f0cd;
						padding: 2px 20px;
						font-weight: bold;
						cursor: default;
					}
					&:hover {
						td {
							background: #f3f0cd;
						}
					}
				}
				&.toolbar {
					th {
						padding 5px 20px;
					}
				}
				&.unread {
					font-weight: bold;
				}
			}
		}
		&.table-checkboxes {
			> tbody {
				> tr {
					&.input-active {
						background: #ebf2e6;
					}
					> td {
						&:first-child {
							padding: 2px 20px;
						}
					}
				}

			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.table {
		thead, tbody, tfoot {
			tr {
				th, td {
					&.showOnTablet {
						display: table-cell;
					}
					&:first-child {
						padding-left: 20px;
					}
					&.text-right {
						text-align: left;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {
  .table {
	thead, tbody, tfoot {
	  tr {
		th, td {
		  &.showOnModarateBig {
			display: table-cell;
		  }
		  &:first-child {
			padding-left: 20px;
		  }
		  &.text-right {
			text-align: left;
		  }
		}
	  }
	}
  }
}

@media (min-width: $screen-md-min) {
	.table {
		thead, tbody, tfoot {
			tr {
				th, td {
					&.showOnDesktop {
						display: table-cell;
					}
					&.text-right-portrait {
						text-align: left;
					}
				}
			}
		}
		&.table-mobile-optimized {
			> thead,
			> tbody {
				tr {
					th,
					td {
						padding-top: 0;
						padding-bottom: 0;
						padding-left: 0;
						table {
							> tbody {
								tr {
									td {
										display: table-cell;
										padding-top: 8px;
										padding-bottom: 5px;
										padding-left: 5px;
										word-break: break-word;
										&.children-date-cell {
										  padding-left: 6px;
										  .widget & {
											padding-left: 100px;
										  }
										}
										&:first-child {
											padding-left: 40px;
											padding-top: 5px;
											padding-bottom: 5px;
										}
										.child-toggle {
											background-size: 6px 12px;
											width: 20px;
											height: 20px;
											position: relative;
											top: 3px;
											margin: 0 10px 0 5px;
											&.child-shown {
												background-image: url("../../img/icons/chevron-down.svg");
												background-size: 12px 6px;
											}
										}
										&:first-child,
										&:nth-child(2) {
											width: 30%
										}
										&:nth-child(3) {
											width: 20%
										}
										&:last-child {
											text-align: right;
										}
									}
								}
							}
						}
						&.table-cell-empty {
							display: table-cell;
						}
					}
					&:hover {
						.dropdown {
							display: none;
						}
					}
					&.parent-node {
						table {
							td {
								&:first-child {
									padding-left: 5px;
								}
							}
						}
					}
					&.child-node {
						table {
							td {
								padding-left: 20px;
								&:first-child {
									padding-left: 70px;
									&.with-children {
										padding-left: 35px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.modal-lgfull{
	  width: 900px;
	}
	.table {
		thead, tbody, tfoot {
			tr {
				th, td {
					&.showOnLargeDesktop {
						display: table-cell;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-very-large) {
  .table {
	thead, tbody, tfoot {
	  tr {
		th, td {
		  &.showOnVeryLargeDesktop {
			display: table-cell;
		  }
		}
	  }
	}
  }
}

body.ipad.modal-open {
  .modal.entity-dialog-modal-container {
	overflow-y: scroll !important;
  }
}

.modal-open {
  position: inherit;
  width: 100%;
  .modal.entity-dialog-modal-container {
	overflow-y: hidden;
  }
}

@media (min-width: $screen-md-min) {
	.modal-open.full-screen-height-modal {
		.modal .modal-dialog.modal-full .modal-content .modal-container .modal-scroll-area {
			overflow-y: hidden;

			& > .entity-dialog-container-1 {
				height: 100%;

				& > .entity-dialog-container-2 {
					height: 100%;

					& > nx-dialog-content {
						height: 100%;
						display: block;

						& > .modal-body {
							height: 100%;
						}
					}
				}
			}
		}
	}
}

.react-modal-workaround {
  & > .modal > .modal-dialog.modal-full {
    background-color: unset;
  }
}

.modal {
	-webkit-overflow-scrolling: touch;

	.modal-dialog {
		margin: 100px auto 50px;
		display: none;
		.modal-content {
			border: 0;
			border-radius: 0;
			.modal-header {
				padding: 25px 20px 15px;
				border-bottom: 0;
				overflow: hidden;
				h3 {
					float: left;
					font-weight: 100;
				}
			}
			.modal-body {
				overflow: visible;
				width: 100%;
				min-height: 230px;
				padding: 0;
				&.modal-body-max-height {
					height: 30vh;
				}
				.modal-body-ignore-height & {
					height: auto !important;
				}
				.modal-body-inner, .form-group {
				  &.column-flex {
					-ms-flex: 1;
					-ms-flex-direction: column;
					flex: 1;
					flex-direction: column;
				  }
				}
			  .modal-border{
				overflow: auto;
				margin-left: 10px;
				margin-right: 10px;
				border-style: solid;
				border-width: 1px;
				border-color: #DADADA;
			  }
				.modal-body-inner {
					padding: 0 20px;
				  .modal-body-scroll {
						overflow-x: hidden;
						overflow-y: auto;
						max-height: 50vh;
					}
				  &.column-flex {
					  display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
				  }
				}
				.form-group {
					border: 0;
					&.border-bottom {
						border-bottom: 1px solid #dbdbdb;
						padding-left: 20px;
						.btn-secondary {
							border-bottom: 3px solid #c9c9c9;
							border-radius: 0;
						}
					}
					&.form-date-time {
						input {
							width: 49%;
							float: left;
							&:first-child {
								margin-right: 2%;
							}
						}
						label {
							display: block;
							+ input {
								margin-right: 2%;
							}
						}
					}
					@media (min-width: $screen-sm-min) {
						&.form-date-time {
							input {
								width: auto;
								float: initial;
								&:first-child {
									margin-right: auto;
								}
							}
							label {
								display: inline;
								+ input {
									margin-right: auto;
								}
							}
						}
					}
					&.deactivated {
						background: #eaeaea;
					}
					&.form-table-group {
						padding: 0;
						.table {
							margin: 0;
							table-layout: fixed;
							thead, tbody {
								tr {
									th, td {
										padding: 10px;
										width: 100%;
										display: block;
										vertical-align: middle;
										&.table-checkbox {
											display: none;
											+ th, td {
												width: auto;
											}
										}
										&.has-error {
											background: #f7e1e1;
										}
										.table-panel {
											border: 1px solid #a6a59d;
											border-radius: 5px;
											margin-top: 10px;
											&:first-child {
												margin-top: 0;
											}
											.table {
												border-radius: 5px;
												tr {
													th {
														&:first-child {
															border-radius: 5px 0 0 0;
														}
														&:last-child {
															border-radius: 0 5px 0 0;
														}
													}
												}
											}
											.table-header {
												font-weight: bold;
												color: #000;
											}
										}
										.table {
											position: relative;
											thead, tbody {
												tr {
													th, td {
														padding-right: 10px;
														padding-left: 10px;
														border: 0;
														width: 100%;
														display: block;
														height: auto;
														&.table-checkbox {
															display: none;
														}
														&.table-input {
															vertical-align: top;
															&:last-child {
																position: absolute;
																top: 7px;
																right: 0;
																width: 40px;
															}
														}
														.form-group {
															padding: 0;
															label {
																float: left;
																position: relative;
																top: 7px;
																width: 25%;
															}
															.form-control {
																width: 55px;
															}
															textarea {
																float: left;
																width: 75%;
																display: block;
																resize: none;
																&.form-control {
																	width: 75%;
																}
															}
														}
													}
													&.has-error {
														th,
														td {
															background: #f7e1e1;
														}
													}
												}
											}
											thead {
												tr {
													border-bottom: 1px solid #cccccc;
													th {
														background: #f4f4f4;
														.remove-panel {
															background-image: url("../../img/icons/icon_remove_colorful.svg");
															width: 20px;
															height: 20px;
															cursor: pointer;
															float: right;
															position: relative;
															top: 8px;
														}
													}
												}
											}
											tbody {
												tr {
													border-bottom: 0;
													td {
														padding-top: 10px;
														padding-bottom: 10px;
													}
												}
											}
										}
									}
									&:hover {
										.table-panel {
											.dropdown {
												display: block;
											}
										}
									}
								}
							}
							thead {
								tr {
									th {
										vertical-align: middle;
										padding-top: 5px;
										padding-bottom: 5px;
										border-top: 2px solid #b7c4c8;
										border-bottom: 0;
										font-weight: normal;
										background: $header-lvl1-bground;
										height: 43px;
										display: none;
										&:first-child {
											font-weight: bold;
										}
										&.table-header {
											width: 65px;
											display: table-cell;
											color: $title-color;
											h3 {
												font-size: 16px;
											}
										}
										.btn {
											display: none;
										}
										.btn-group {
											.btn.btn-secondary {
												border-bottom: 3px solid #c9c9c9;
												border-radius: 0;
											}
										}
										.ui-select-container {
											.btn {
												display: block;
											}
											.ui-select-choices {
												max-height: 220px;
												overflow-x: hidden;
												overflow-y: auto;
											}
										}
									}
									&:hover .btn-group {
										display: inline-block;
									}
								}
							}
							tbody {
								tr {
									td {
										padding-top: 5px;
										padding-bottom: 5px;
										border: 0;
										&.table-citizen {
											background: $header-lvl2-bground;
											padding-top: 3px;
											padding-bottom: 3px;
											border-top: 1px solid #b7c4c8;
											border-bottom: 0;
											min-height: 41px;
											.form-inline {
												> a {
													color: #3782c4;
													margin-right: 10px;
													line-height: 34px;
													display: block;
													cursor: pointer;
													&:hover {
														color: #2f6ea7;
													}
												}
												.ui-select-container {
													width: 100%;
													display: block;
													height: 34px;
													@media (min-width: $screen-sm-min) {
														width: 400px;
													}
													&.ui-select-search {
														&:after {
															border: 0;
														}
													}
													.ui-select-match {
														height: 34px;
														outline: 0;
													}
													.caret {
														display: none;
													}
												}
											}
										}
										td {
											label {
												font-weight: bold;
											}
										}
									}
									&:last-child {
										td {
											border-bottom: 0;
										}
									}
									&.has-error {
										td {
											background-color: #f7e1e1;
										}
									}
								}
								+ tbody {
									border-top: 0;
								}
							}
						}
					}
					@media (min-width: $screen-sm-min) {
						&.form-table-group {
							.table {
								thead, tbody {
									tr {
										th, td {
											width: 20%;
											display: table-cell;
											&.table-checkbox {
												padding-left: 10px;
												width: 28px;
												display: table-cell;
											}
											.table {
												thead, tbody {
													tr {
														th, td {
															display: table-cell;
															width: auto;
															&.table-header {
																width: auto;
																a {
																	cursor: pointer;
																}
															}
															&.table-checkbox {
																padding-left: 10px;
																width: 28px;
																display: table-cell;
															}
															&.table-input {
																padding: 5px 0 5px 10px;
																width: 20%;
																&:last-child {
																	position: inherit;
																	width: 60px;
																	display: table-cell;
																	padding-right: 10px;
																}
															}
															&.table-input-wide {
																padding: 5px 0 5px 10px;
																width: 30%;
																&:last-child {
																	position: inherit;
																	width: 60px;
																	display: table-cell;
																	padding-right: 10px;
																}
															}
														}
													}
												}
												thead {
													tr {
														border-bottom: 0;
														th {
															border-bottom: 1px solid #cccccc;
															.form-group {
																label {
																	margin-right: 5px;
																	width: auto;
																}
															}
														}
													}
												}
											}
										}
									}
								}
								thead {
									tr {
										th {
											height: auto;
											display: table-cell;
											.btn {
												display: block;
											}
										}
									}
								}
								tbody {
									tr {
										border: 0;
										td {
											padding-top: 10px;
											padding-bottom: 10px;
											border: 0;
											border-bottom: 1px solid #d9d9d9;
										}
									}
								}
							}
						}
					}
				}
				.table.table-static {
					margin: 0;
					tr {
						td {
							padding: 8px 10px 4px;
							span {
								position: relative;
								top: -4px;
							}
							&:last-child {
								text-align: right;
							}
						}
					}
				}
				.sender {
					padding-left: 10px;
				}
				.text-container {
					padding: 10px;
					padding-left: 20px;
					border-bottom: 1px solid #ddd;
				}
			}

			.has-flex {
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				align-items: stretch;
				flex-flow: row wrap;
			}
			#price-definition {
				.modal-body {
					max-height: none;
					.btn-container {
						margin-top: 10px;
						.btn-group {
							.btn-primary {
								border: 0;
								border-bottom: 3px solid #34911f;
								border-radius: 0;
								&:hover {
									border-bottom-color: #0b3233;
								}
							}
							&.open {
								.btn-primary {
									background-color: #155758;
									border-bottom-color: #0b3233;
									box-shadow: 0 0 0;
								}
							}
						}
					}
					td.form-group {
						border-bottom: 1px solid #c9c9c9;
						padding-left: 10px;
						padding-right: 10px;
					}
				}
			}
			.modal-footer {
				padding: 15px 20px;
				border-top: 0;
			}
			.btn.btn-secondary {
				border-top: 1px solid #c9c9c9;
				border-right: 1px solid #c9c9c9;
				border-left: 1px solid #c9c9c9;
			}
			.modal-header,
			.modal-footer {
				.btn {
					margin: 0 7px 5px 0;
					&.btn-secondary {
						padding-bottom: 5px;
					}
				}
			}
		}
		&.modal-med,
		&.modal-lg,
		&.modal-lgfull,
		&.modal-error {
			display: block;
		}
		&.modal-error {
			.server-error {
				> div {
					margin-bottom: 10px;
				}
			}
			.modal-body-inner {
				> div {
					overflow: auto;
					max-height: 45vh;
				}
			}
		}
		&.modal-lgfull,
		&.modal-med,
		&.modal-lg {
			.modal-body {
				.alert-container {
					.alert {
						margin-right: 20px;
						margin-left: 20px;
					}
				}
				.form-group {
					padding-right: 20px;
					padding-left: 20px;
				}
				.tree-container {
					margin-bottom: 0;
				}
				&.column-flex {
					display: -webkit-flex;
				  display: -ms-flexbox;
				  display: flex;
				}
			}
			.modal-footer {
				.btn {
					margin: 0 0 0 7px;
					&.btn-float-left {
						margin: 0 7px 0 0;
						float: left;
					}
				}
			  	.button-dropdown-on-modal-margin-fix {
					.btn.dropdown-toggle {
					  	margin-left: 0px;
					}
				}
			}
			.tree-container {
				max-height: 45vh;
				overflow: auto;
				padding-bottom: 1px;
				margin: 0;
				border: 1px solid #dedede;
				-webkit-overflow-scrolling: touch;
			}
			.row {
				margin-left: 0;
				margin-right: 0;
			}
			.row.large-form,
			.row.medium-form {
				max-height: 45vh;
				overflow-x: hidden;
				overflow-y: auto;
				padding-bottom: 1px;
				border: 1px solid #dedede;
				margin: 0 20px;
				> div {
					padding: 0 0 20px 0;
					+ div {
						border-left: 1px dashed #c9c9c9;
					}
					.control-label {
						&:before {
							position: initial;
						}
					}
					.form-extra-text {
						position: relative;
						top: 5px;
						left: 5px;
					}
				}
				.metadata {
					border-bottom: 1px solid #ddd;
					height: auto;
					min-height: auto;
					max-height: auto;
				}
			}

			treecontrol li div.lastTreeElement {
				border-bottom: none
			}
			.highlighted {
				font-weight: bold;
			}
			.mode-date-container {
				height: 37px;
				vertical-align: middle;
				display: table-cell;
				min-width: 350px;
			}
			.date-form-control {
				width: 95px;
				float: none;
				margin-left: 10px;
			}
		}
		&.modal-lgfull {
		  margin-top: 3vh;
		  max-height: 80vh;
		  .tree-container {
			min-height: 74vh;
		  }
		  .row.large-form,
		  .row.medium-form {
			min-height: 74vh;
		  }
		}
		&.modal-full {
			min-height: 100vh;
			margin: 0;
			background-color: #fff;
			display: block;
			top: 0;
			position: absolute;
			width: 100%;
			.modal-content {
				background-color: transparent;
				box-shadow: 0 0 0 rgba(0, 0, 0, 0);
				.form-horizontal {
					overflow: visible;
				}
				.patient-header,
				.modal-header {
					top: 0;
					width: 100%;
					&.has-pagination {
						//padding-right: 200px !important;
						.pagination-container {
							width: 200px;
							height: 41px;
							position: absolute;
							top: 5px;
							right: 0;
							margin: 0;
							nx-pagination,
							nx-pagination-pagenumbers,
							nx-pagination-previous-next {
								position: absolute;
								right: 0;
							}
						}
					}
				}
				.modal-header {
				  	z-index: 1000;
					.modal-title {
						min-width: 16.6666666666667%;
						width: auto;
						min-height: 1px;
					}
					h3 {
						float: left;
						line-height: 30px;
						margin: 2px 0px 3px 0;
						font-weight: bold;
						padding: 0 20px 0 10px;
					}
					+ .row {
						margin-right: 0;
						margin-left: 0;
					}
				}
				.modal-body {
					min-height: 0;
					background-color: #fff;
					max-height: none;
					.modal-body-inner {
						padding: 10px;
					}

				}
				.modal-footer {
					div {
						text-align: left;
					}
				}
				.modal-header,
				.modal-footer {
					background-color: #addfe9;
					border: 0;
					padding: 7px 10px 0;
					min-height: 50px;
					overflow: visible;
					&.notRendered {
						display: none;
					}
					.btn-group {
						margin: 0 7px 2px 0;
						.btn {
							margin: 0;
							&.btn-secondary,
							&.btn-primary {
								border-radius: 0;
							}
							&.btn-secondary {
								border-top: 1px solid #c9c9c9;
								border-right: 1px solid #c9c9c9;
								border-left: 1px solid #c9c9c9;
								border-bottom: 3px solid #c9c9c9;
							}
							&.btn-primary {
								border-top: 0;
								border-right: 0;
								border-left: 0;
								border-bottom: 3px solid #34911f;
								&.btn-additional {
									border-left: 1px solid #34911f;
								}
								&:hover {
									border-bottom-color: #0b3233;
								}
							}
						}
						&.dropdown {
							float: left;
							.btn {
								&:first-child {
									border-right: 0;
								}
							}
							&.open .btn-primary {
								border-bottom-color: #0b3233;
								background-color: #155758;
							}
						}
					}
				}
				.modal-container {
					width: 100%;

					/* modal header is scrolled on small screens */
					@media (min-width: $screen-sm-min) {
						position: fixed;
						top: 0;
						bottom: 0;
					}

					.patient-header {
						position: relative;
					}
					.modal-header {
						position: static;
					}
					.modal-scroll-area {
						position:static;

						/* modal header is scrolled on small screens */
						@media (min-width: $screen-sm-min) {
							overflow-x:hidden;
							overflow-y:auto;
							height: 86%;
						}
						.row {
							margin-left: 0;
							margin-right: 0;
						}
					}
					.modal-scroll-area.mobile-overflow-override {
						/* fix to allow position: sticky to work in full size modal on mobile devices */
						@media (min-width: $screen-sm-min) {
							overflow-x:visible;
							overflow-y:visible;
						}
					}
				}
				.modal-footer {
					padding: 7px 10px 0;
				}
				.modal-double {
					.modal-header {
						.modal-title {
							min-width: 8.3333333333333%;
						}
					}
					.col-sm-6 + .col-sm-6 {
						border-left: 1px solid #c9c9c9;
					}
					.col-sm-5 + .col-sm-7 {
						border-left: 1px dashed #c9c9c9;
					}
				}

				.list {
					.form-separator {
						padding-left: 20px;
					}
				}
			}
		}
		/*&.modal-error {
			width: 800px;
			.modal-header,
			.modal-footer {
				background-color: #addfe9;
				border: 0;
				padding: 5px 10px;
				overflow: hidden;
				height: 45px;
			}
		}*/
	}
	.dropup,
	.dropdown {
		position: relative;
		right: 0;
		top: 0;
		/*
		display: block;
		*/
		border: 0;
		.ui-select-match {
			> span.btn {
				margin: 0;
			}
			.ui-select-toggle {
				.btn {
					margin-right: 10px;
					height: auto;
					border: 0;
				}
			}
		}
		.ui-select-match-text,
		.ui-select-placeholder {
			margin: 0;
		}
		&.ui-select-multiple {
			border: 1px solid #ccc;
			padding-top: 5px;
			min-height: 34px;
			.ui-select-search {
				margin-bottom: 4px;
			}
			.btn {
				height: auto;
				.ui-select-match-close {
					position: relative;
					top: 2px;
				}
			}
		}
	}
	.dropright {
		.dropdown-menu {
		  right: 0 !important;
		  left: inherit !important;
		}
	}
	.dropdown-menu {
		li {
			> button {
				border: 0;
				width: 100%;
				padding: 3px 20px;
				text-align: left;
				background: #fff;
				&:hover {
					color: #303030;
					background-color: #f5f5f5;
				}
			}
		}
	}
	&.fade {
		opacity: 1;
		.modal-dialog,
		.modal.in .modal-dialog {
			-webkit-transform: translate(0, 0);
			-ms-transform: translate(0, 0);
			transform: translate(0, 0);
		}
	}
	.dropup .ui-select-match-text,
	.dropdown .ui-select-match-text,
	.dropup .ui-select-placeholder,
	.dropdown .ui-select-placeholder {
		width: 80%;
		display: inline-block;
		overflow: hidden;
	}
}

.modal-body-ignore-height {
	.modal-body {
		height: auto !important;
	}
}

//those styles have been copied from nexus-react-core with some modifications, see https://dev.azure.com/kmddk/NEXUS/_workitems/edit/1221740
$zindex-backdrop = 1070;
$zindex-modal = 1080;

.nexus-modal {

  &.modal-backdrop {
	z-index: $zindex-backdrop;

	&.in {
	  opacity: 0.8;
	}
  }

  &.modal {
	z-index: $zindex-modal;
	overflow-y: auto;
	-webkit-overflow-scrolling touch

	.modal-dialog {
	  margin: 100px auto 50px;
	  display: block;

	  .modal-content {
		position: relative;
		border: 0;
		border-radius: 0;
		background-color: #fff;

		.modal-body {
		  min-height: 230px;
		  width: 100%;
		  padding: 0 20px;
		}

		.no-padding {
		  padding: 0 10px;
		}

		.modal-header {
		  min-height: 50px;
		  padding: 25px 20px 15px !important;
		  border-bottom: 0;
		  overflow: hidden;

		  .modal-title {
			margin: 0;
			line-height: 1.428571429;
			font-weight: 100;
			color: #155758;
		  }
		}

		.modal-footer {
		  border-top: 0;
		  min-height: 50px;
		  padding: 15px 20px;
		  text-align: right;

		  > * {
			margin: 0 0 0 7px;
			display: inline-block;
		  }
		}
	  }
	}
  }
}
// copy end https://dev.azure.com/kmddk/NEXUS/_workitems/edit/1221740

.metadata {
	border-bottom: 1px solid #ddd;
	label {
		font-size: 13px;
	}
}

.dropup,
.dropdown {
	&.ui-select-multiple {
		.ui-select-match-item {
			&.btn-primary {
				background-color: #fff;
				color: $default-text-color;
			}
			&:hover {
				background-color: #e6e6e6;
			}
		}
		&.ui-select-tags {
			/*height: 34px;*/
			.ui-select-match-item {
				background-color: #e6e6e6;
				&.btn-primary {
					background-color: #e6e6e6;
					color: $default-text-color;
				}
				&:hover {
					background-color: #999;
				}
			}
		}
	}
}

/* when dropdown menu does not fit the screen */
.dropdown-up {
	.btn-group {
		> ul {
			bottom: 100%;
			top: auto;
		}
	}
}
.calendar-up {
	ul {
		bottom: 100%;
		top: auto !important;
	}
	.ui-timepicker-wrapper{
	  top: -155px !important;
	}
}

.form-horizontal {
	.control-label {
		text-align: left;
		&.align-center {
			text-align: center;
		}
	  	&.align-right {
		  text-align: right;
		}
	}

	.form-group {
		padding: 10px;
		border-top: 1px solid #dedede;
		margin: 0;
		.form-extra-text {
			margin: 0 5px;
			position: relative;
			top: 7px;
		}
		&.no-input-element {
			.col-sm-2,
			.col-sm-3 {
				padding-top: 0;
			}
		}
		&.form-expand {
			border-top: 0;
			.radio,
			.checkbox,
			.ui-select-container {
				margin-top: 5px;
				.ui-select-match {
					> span.btn {
						margin: 0;
					}
				}
			}
			.form-indent {
				margin-left: 20px;
			}
		}
		.form-error {
			margin: 5px 0 0 15px;
			display: block;
		}
		&.has-error {
			background-color: rgba(189, 2, 2, 0.12) !important;
		}
		&.required .control-label:before {
			content: "*";
			color: red;
			margin: 0 5px 0 0;
		}
		.ui-select-container {
			.ui-select-match {
				> span.btn {
					margin: 0;
				}
			}
			&:disabled,
			&[disabled] {
				cursor: default;
				background-color: #eee !important;
				> .ui-select-match > .ui-select-toggle {
					background-color: #eee !important;
					opacity: 1;
				}
			}
		}
		&.form-inline {
			.radio,
			.checkbox {
				label {
					padding-left: 20px;
					input[type="radio"],
					input[type="checkbox"] {
						position: absolute;
						margin-left: -20px;
					}
				}
			}
			span {
				margin: 0 5px;
				position: relative;
				top: 2px;
			}
		}
		.form-inline {
			&.form-content-toggled {
				@media (min-width: $screen-sm-min) {
					height: 34px;
				}
				label {
					margin: 4px 0 0 0;
					input[type="checkbox"],
					input[type="radio"] {
						position: relative;
						top: 4px;
					}
				}
			}
		}
		&.form-group-expand {
			.ui-select-container {
				display: inline-block;
				width: 40%;
				margin-left: 10px;
				> div {
					span {
						margin: 0;
					}
					> span > span > span {
						top: -4px;
						margin: 0 3px 3px 0;
						> span + span > span {
							top: -2px;
						}
					}
				}
			}
			.expand-container {
				padding-left: 24px;
				min-height: 34px;
				label {
					padding: 7px 0 7px 20px;
				}
				.expand {
					position: absolute;
					width: 20px;
					height: 20px;
					left: 0;
					top: 7px;
					cursor: pointer;
					background-image: url("../../img/icons/chevron-right.svg");
					background-repeat: no-repeat;
					background-size: 40% 80%;
					background-position: center;
				}
				.expand-content {
					display: none;
					margin-top: 10px;
					padding: 10px;
					.ui-select-container {
						width: 100%;
						margin: 0;
					}
					.fmkXmlRenderPanel {
						margin: 10px 0 0 0;
						&:first-child {
							margin-top: 0;
						}
						* {
							float: none;
							line-height: 18px;
							font-size: 14px;
							color: $default-text-color;
						}
						.fmk-label {
							label, span, div {
								float: left;
								ul {
									padding-left: 20px;
								}
							}
							label {
								padding-top: 0;
								padding-bottom: 0;
							}
						}
					}
				}
			}
			> label {
				padding-top: 12px;
			}
			&.expanded {
				.expand-container {
					.expand {
						background-image: url("../../img/icons/chevron-down.svg");
						background-size: 80% 40%;
					}
					.expand-content {
						display: block;
					}
				}
			}
		}
		&.has-post-control-label {
			.ui-select-bootstrap {
				.ui-select-toggle {
					> .caret {
						display: none;
					}
				}
			}
		}
	}
	.form-separator {
		padding: 2px 10px;
		border-top: 2px solid #b7c4c8;
		background-color: $header-lvl1-bground;
		min-height: 42px;
		&.has-elements {
			padding-top: 10px;
			padding-bottom: 10px;
			border: 0;
		}
		&:before,
		&:after {
			content: " ";
			display: table;
		}
		&:after {
			clear: both;
		}
		&.required h3:before {
			content: "*";
			color: red;
			margin: 0 5px 0 0;
		}
		.btn-group {
			margin-bottom: 0;
		}
		.ui-select-container {
			.ui-select-match {
				outline: none;
				> span {
					border: 0;
					.ui-select-placeholder.text-muted {
						color: #000 !important;
					}
					a {
						border: 0;
						padding-top: 3px;
					}
				}
				.btn-xs {
					height: 20px;
				}
			}
		}
		input[type="checkbox"] {
			margin: 9px 0;
		}
		h3 {
			margin: 0;
			font-size: 16px;
			font-weight: bold;
			line-height: 36px;
		}
		&.seperator-level-02 {
			border-top: 1px solid #b7c4c8;
			background-color: $header-lvl2-bground;
			h3 {
				font-size: 15px;
				font-weight: 400;
			}
		}
		&.seperator-level-03 {
			border-top: 1px solid #b7c4c8;
			background-color: $header-lvl3-bground;
			h3 {
				font-size: 14px;
				font-weight: bold;
				font-color: #686868;
			}
		}
	}
	.input-group {
		margin-bottom: 0;
	}
	.radio,
	.checkbox {
		min-height: 20px;
	}
	.radio,
	.checkbox,
	.radio-inline,
	.checkbox-inline {
		padding-top: 0;
	}
}

.form-group-expandable {
	.form-separator {
		position: relative;
		&.seperator-level-02,
		&.seperator-level-03 {
			padding-left: 40px;
		}
		&.no-extra-padding {
		  padding-left: 20px;
		}
		.expand {
			position: absolute;
			left: 10px;
			top: 10px;
			width: 20px;
			height: 20px;
			cursor: pointer;
			background-image: url("../../img/icons/chevron-right.svg");
			background-repeat: no-repeat;
			background-size: 40% 80%;
			background-position: center;
		}
	}
	&.error {
		border: 0;
		.seperator-level-02,
		.seperator-level-03 {
			border-top: 1px solid #a94442;
			border-bottom: 1px solid #a94442;
			background-color: rgba(189, 2, 2, 0.12);
		}
	}
	&.expanded {
		.expand {
			background-image: url("../../img/icons/chevron-down.svg");
			background-size: 80% 40%;
		}
		.form-group-expandable-inner {
			display: block;
		}
	}
}

.form-group-expandable-inner {
	border-bottom: 1px solid #ccc;
	display: none;
	.form-group {
		padding-left: 30px;
	}
}

@media (min-width: $screen-sm-min) {
	.form-horizontal {
		.control-label {
			text-align: left;
			padding-left: 10px;
			padding-right: 10px;
		}
		.form-group {
			&.required .control-label:before {
				position: absolute;
				left: 0;
			}
		}
	}
}

.form-group {
	&.form-group-total {
		label {
			width: 56%;
		}
		input {
			width: 55px;
		}
		&.has-text {
			label {
				width: 62%;
			}
		}
	}
	.nav {
		background: transparent;
		padding: 0;
		&.nav-tabs {
			> li {
				border: 0;
				background: #b3dde6;
				a {
					color: $default-text-color;
					padding: 10px 20px;
				}
				&:first-child {
					border: 0;
				}
				&:hover {
					background: #b3dde6;
				}
				&.active {
					background: #21a8c6;
					a {
						background: transparent;
						color: #fff;
					}
					&:hover {
						background: #21a8c6;
						a {
							color: #fff;
						}
					}
				}
				a {
					background: transparent;
				}
			}
		}
	}
}

.form-group .tab-container {
	border: 5px solid #21a8c6;
}

.form-group {
	.two-line-ui-select {
		.ui-select-toggle.btn.btn-default.form-control {
			height: 54px
			> a {
				margin-top: 11px;
			}
		}
	}
}

.form-group .tab-container,
.price-container {
	padding: 0;
	overflow-x: auto;
	overflow-y: hidden;
	&.no-scroll {
		overflow: visible;
	}
	.time-add-icon {
		background-image: url("../../img/icons/icon_add_colorful.svg");
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position: center center;
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	.remove-row-icon {
		background-image: url("../../img/icons/icon_remove_colorful.svg");
		background-repeat: no-repeat;
		background-size: 15px 15px;
		background-position: center center;
		width: 20px;
		height: 20px;
		cursor: pointer;
		display: inline-block;
		float: right;
	}

	.table {
		margin: 0;
		> thead,
		> tbody {
			> tr {
				> th,
				> td {
					padding: 15px 10px;
					background: #fff;
					border-bottom: 1px solid #c9c9c9;
					vertical-align: middle;
					position: static;
					&:first-child {
						min-width: 100px;
					}
					&:last-child {
						text-align: right;
					}

					&.time-cell, &.time-header {
						background: #d9eef3;
						&:nth-child(odd) {
							background: #f0f8fa;
						}
					}
					.dropdown-menu {
						td, th {
							padding: 0;
							text-align: center;
						}
					}
				}
				th {
					&.time-add {
						padding-right: 25px;
						padding-left: 25px;
						background-color: #daedce;
					}
				}
				td {
					padding-top: 10px;
					padding-bottom: 10px;
					input[type=text] {
						width: 100%;
					}
					&.time-add {
						text-align: center;
						background: #eff7e8;
					}
					label {
						font-weight: bold;
						span {
							font-weight: normal;
						}
					}
					&.remove-row {
						.remove-row-icon {
							top: 17px;
						}
					}
				}
				&.time-add {
					td {
						height: 40px;
						.time-add-icon {
							width: 100%;
						}
						&:first-child {
							background-color: #daedce;
							.time-add-icon {
								top: 10px;
							}
						}
						&.time-cell {
							background: #eff7e8;
						}
					}
				}
			}
		}
		> tbody {
			> tr {
				> td {
					&:last-child {
						padding: 0 20px 0 10px;
					}
				}
			}
		}
		> thead {
			> tr {
				> th {
					&.time-cell {
						min-width: 119px;
					}
					&.time-header {
						min-width: 60px;
					}
					&:last-child {
						min-width: 60px;
					}
				}
			}
		}
	}
	&.no-padding {
		padding: 0;
	}
}


.price-container {
	border: 0;
	height: 50vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.ui-select-match-text,
.ui-select-placeholder {
	margin: 6px 0;
	display: block;
}

/*.ui-select-container::after {
	border-top: 4px solid;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: "";
	position: absolute;
	right: 10px;
	width: 0;
	height: 0;
	top: 50%;
	margin-top: -2px
}*/

.tooltip-icon {
	width: 17px;
	height: 17px;
	display: inline-block;
	position: relative;
	top: 4px;
	left: 2px;
	background-image: url("../../img/question-circle.svg");
	cursor: pointer;
	background-size: 100% 100%;
}

.local-dosageperiod-icon {
  width: 17px;
  height: 17px;
  display: inline-block;
  position: relative;
  background-image: url("../../img/icons/L-icon.svg");
  cursor: pointer;
  background-size: 100% 100%;
}

.tooltip.wide-tooltip {
	.tooltip-inner {
		max-width:600px;
	}
}

.tooltip {
	top: 0;
	.tooltip-inner {
		background-color: #addfe9;
		color: #000;
		max-width: 400px;
		text-align: left;
		@media (max-width: 450px) {
			max-width: calc(100vw - 90px);
		}
	}
	&.top {
		.tooltip-arrow {
			border-top-color: #addfe9 !important;
		  	display: none;
		}
	}
	&.right {
		.tooltip-arrow {
			border-right-color: #addfe9 !important;
		 	display: none;
		}
	}
	&.bottom {
		.tooltip-arrow {
			border-bottom-color: #addfe9 !important;
		  	display: none;
		}
	}
	&.left {
		.tooltip-arrow {
			border-left-color: #addfe9 !important;
			display: none;
		}
	}


}

.input-group {
	width: 100%;
	.input-group-addon {
		span.color-pick {
			width: 10px;
			height: 10px;
			display: inline-block;
		}
	}
	.form-control:first-child {
		border-radius: 4px;
		background: #fff;
	}
}

.form-control {
	background-color: #fff; /* !important not allowed */
	color: #000;
}

.btn {
	border-radius: 0;
	border: 0;
	margin: 0 7px 0 0;
	transition: .1s all;
	&.btn-default {
		color: $default-text-color;
		background-color: #fff;
		border-color: #b4b4b4;
	}
	&.btn-primary {
		background-color: #43b02a;
		border-bottom: 3px solid #34911f;
		&.btn-additional {
			border-left: 1px solid #34911f;
			.open & {
				background-color: #155758;
				border-bottom: 3px solid #0b3233 !important;
				border-left-color: #0b3233 !important;
			}
		}
		&:hover {
			background-color: #155758;
			border-bottom: 3px solid #0b3233;
			transition: .1s all;
		}
	}

	&.btn-secondary {
		background-color: #fff;
		border-bottom: 3px solid #c9c9c9;
		&.btn-additional {
			border-left: 1px solid #c9c9c9;
		}
		&.active {
			background-color: #f8f8f8; /* pressed toggle button */
		}
		&:hover {
			background-color: #f2f2f2;
			transition: .1s all;
		}
	}
	.btn-group & {
		margin: 0;
	}
}

.panel-header {
	.ui-select-bootstrap {
		i.caret {
			display: none;
		}
	}
}

.ui-select-bootstrap .ui-select-toggle {
  .glyphicon.glyphicon-remove {
	top: 4px;
  }
}

.table-hover > tbody > tr:hover {
	input[type=checkbox] {
		z-index: 1;
	}
}

@media (min-width: $screen-sm-min) {
	.ui-timepicker-input,
	.ui-timepicker-wrapper {
		width: 80px !important;
	}
}

.ui-timepicker-wrapper {
	height: 130px;
}

.radio label, .checkbox label {
	> span:first-of-type {
		margin-left: 5px;
	}
}
.radio {
	&.radio-inline-text {
		input[type="text"] {
			display: inline;
			width: 113px;
			margin: 4px 2px;
		}
	}
	&.radio-inline-date {
		input[type="text"] {
			display: inline;
			width: 189px;
			margin: 0 0 0 6px;
		}
	}
}

/* Todo: styling for fullscreen modals, attempt at fixing buggy iOS support
.modal-open {
	overflow-y: auto !important;
}
.modal-open .pace + div {
	display: none;
}
.modal-open .modal-backdrop.fade.in {
	display: none;
}
.modal-open .modal {
	position: initial;
}
.modal-open .modal-dialog.modal-full {
	width: 100%;
	overflow: hidden;
	position: absolute;
	height: auto;
}*/

/*div[uib-modal-window] {
	display: none;
}*/

.text-muted {
	color: #9e9e9e !important;
}

a.text-muted:hover {
	text-decoration: none;
	color: #9e9e9e;
}

.alert-container {
	padding-bottom: 10px;
}

.alert {
	color: $default-text-color;
	margin: 10px 10px 0;
	padding: 10px;
	&.alert-info {
		background-color: #d9edf7;
		border: 1px solid #9acfea;
	}
	&.alert-warning {
		background-color: #f3df81;
		border: 1px solid #debb16;
	}
	&.alert-danger {
		background-color: #ff5353;
		border: 1px solid #e80000;
	}
	ul {
		padding-left: 30px;
	}
}

.col-sm-12 {
	padding-left: 0;
	&:before {
		position: relative;
	}
}

/* gray out disabled pagination buttons */
.pagination {
	> .disabled {
		> a:after {
			content: "";
			opacity: 0.8;
			background-color: #fff;
			width: 38px;
			height: 30px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

/* override to make <ui-select> border work */
.has-error {
	.form-control {
		border-color: $state-danger-text !important;
	}
}

.form-control[disabled],
fieldset[disabled] .form-control,
input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"],
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn,
.radio label[disabled],
.checkbox label[disabled] {
	cursor: default;
}

/* explicit styles - some datepickers get overriden (on modal popups) */
.uib-datepicker-popup {
	table {
		thead, tbody {
			tr {
				th {

				}
				th, td, th:first-child, td:first-child {
					background-color: #fff;
					padding: 0;
					border: 0;
				}
			}
		}
	}
}

.dropdown-toggle:focus {
	outline: 5px auto -webkit-focus-ring-color !important;
	outline-offset: -2px !important;
}

.disableHover {
  overflow: visible;
  background-color: #F4F4F4;
}
.activity-list-table {
  border-collapse: separate;
}

table.activity-list-table tbody {
	tr.disableHover td {
		padding-top: 4px;
		padding-bottom: 0;
	}
	tr td.patient-centric-activity-list-checkbox {
		padding: 2px 20px;
	}
}

.ui-select-bootstrap.ui-select-container {
  &.default-cursor-on-disabled-choice {
	.ui-select-choices-row.disabled > span {
	  cursor: default;
	}
  }
}

// this is a fix for https://github.com/angular-ui/ui-select/issues/1826
.ui-select-bootstrap .ui-select-toggle .ui-select-match-text span {
  width: auto;		// initial not working on ie
  width: initial;
}
// fix end
