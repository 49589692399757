/* TODO: Kenneth, please make this beautiful! */
#handout-schema-date
	width 200px

.patient-content {
	.alert {
		.tooltip {
			max-width: none;
		}
	}
	.tree-container {
		.tree-item-status {
			margin-right: 15px;
		}
	}
}

.fmk-reviewed-by-container
	padding: 5px 15px 5px 15px

#fmk-info-header-tooltip, #fmk-warning-header-tooltip, #fmk-reviewed-by-tooltip,
#fmk-suspended-tooltip, #fmk-not-reviewed-tooltip{
	text-align: left;
	width: 400px;
	h3 {
		float: inherit;
	}
}

#fmk-danger-icon{
  width: 18px;
  height: 18px;
}

.fmk-hospitalization-tooltip, fmk-not-reviewed-tooltip {
	text-align: left;
	width: 350px;
	h3 {
		float: inherit;
	}
}

.time-set-icon {
	background-image: url("../../img/icons/Clock.svg");
	background-repeat: no-repeat;
	background-size: 20px 20px;
	background-position: center center;
	width: 20px;
	height: 20px;
	cursor: pointer;
	float: left;
}

.time-set-text {
  cursor: pointer;
}

.medication-card-icon {
	width: 20px;
	height: 20px;
	float: left;
	margin: 5px;
}

.medication-card-icon-inactive {
	@extends .medication-card-icon
	opacity: 0.5
}

.medication-administration-icon {
	width: 16px;
	float: right;
}

.medication-info-panel {
  margin-bottom: 10px;
}

.medication-info-panel-container {
  min-height: 0;
  div {
	min-height: 0;
  }
}

.dosageFreeText-break-word-medicationCard {
  word-wrap: break-word;
  max-width: 400px;
}

.dosageFreeText-break-word-tooltip {
  word-wrap: break-word;
  max-width: 350px;
}

.dosageFreeText-break-word-med-view {
  word-wrap: break-word;
  max-width: 500px;
}

.dosageFreeText-break-word {
  word-wrap: break-word;
}

#fmk-info-header
	padding-left : 10px

.future_dosage_period {
	background: url("../../img/icons/Arrow-Right_2.svg") no-repeat;
	background-size: cover;
}

.past_dosage_period {
	background: url("../../img/icons/Arrow-Left_2.svg") no-repeat;
	background-size: cover;
}

.past_dosage_period_on_fmk {
  background: url("../../img/icons/Arrow-Left_2.svg") no-repeat;
  background-size: cover;
}

.future_unstructured_dosage {
	background: url("../../img/icons/Arrow-Right_2.svg") no-repeat;
	background-size: cover;
}

.past_unstructured_dosage {
	background: url("../../img/icons/Arrow-Left_2.svg") no-repeat;
	background-size: cover;
}

.future_medication {
	background: url("../../img/icons/Arrow-Right_2.svg") no-repeat;
	background-size: cover;
}

.no_future_days_on_active_period {
  	background: url("../../img/icons/Information.svg") no-repeat;
  	background-size: cover;
}

.fmk-historical-medicine {
    font-style: italic;
  	color: #9e9e9e;
}

#fmk-info-header-tooltip
	width 350px

#dosageTranslationText {
	width: 20%;
}

.fmk-info-header-freetext {
  padding: 5px 15px 5px 15px;
  display: table;
  a {
	color: inherit;
  }
  span {
	display: table-cell;
	vertical-align: middle;
  }
}

.fmk-new-dosageperiod-indicator {
	font-style: italic;
	top: -20px;
	left: -10px;
	position: relative;
}

i.datepicker-icon {
	cursor: pointer;
}

.medication-list {
	.no-data {
		padding-left: 20px;
	}
}

.medication-list-scrollbar {
	display: none;
}

.medication-list-container {
	display: none;
}

toggle-cell()
	&.toggle-cell {
		input {
			position: absolute;
			top: -1000px;
			left: -1000px;
		}
		label {
			display: block;
			width: 20px;
			height: 20px;
			overflow: hidden;
			margin: 0;
			background: url("../../img/icons/arrowDown.svg") no-repeat center;
			background-size: 60% 30%;
			cursor: pointer;
		}
		&.closed {
			label {
				background-image: url("../../img/icons/arrowRight.svg");
				background-size: 30% 60%;
			}
		}
	}

.pn-medication-list-container {
	display: block
	overflow: visible !important
	table {
	table-layout: fixed
	thead,
	tbody {
	  tr {
		th,
		td {
		  position: static;
		  vertical-align: top;
		  padding: 10px 5px;
		  &.med-view {
			display: table-cell;
		  }
		  &.large-view {
			display: none;
		  }
		  &:first-child {
			border-right: 1px solid #dedede;
			width: 40px;
			padding-right: 20px;
			padding-left: 20px;
			text-align: center;
			vertical-align: middle;
		  }
		  input {
			padding: 0;
			margin: 0;
			width: 18px;
			height: 18px;
			display: block;
		  }

		}
		&:hover td {
		  padding-top: 10px;
		  padding-bottom: 10px;
		}
	  }
	  .pn-medicine-row:hover{
		background-color: #ebf2e6;
	  }
	  .pn-medicine-row {
		.alert-pn-medicine {
			height: 100%;
			.alert-pn-medicine-header {
				margin: 0;
				padding: 5px;
				padding-top: 1px
			}
			.alert-pn-medicine-row {
				margin: 0;
				padding: 5px;
			}
		}
	  }
	}
	tbody {
	  tr {
		td {
		  &.admin-time-odd,
		  &.admin-time-even {
			text-align: center;
			img {
			  margin: 4px 0 4px 0;
			}
		  }
		  &.admin-time-odd {
			background-color: #F0F8FA;
		  }
		  &.admin-time-even {
			background-color: #D9EEF3;
		  }
		  .timebox {
			background: #fff;
		  }
		  .timebox table tbody tr td {
			padding: 0;
			border: 0;
			background: #b3dde6;
		  }
		  .timebox table tbody tr td {
			padding: 0;
			border: 0;
			background: #b3dde6;
		  }
		}
		&.medication-bottom {
		  td {
			border-bottom: 0;
			height: 18px;
			padding: 0;
			&:first-child {
			  border: 0;
			}
		  }
		}
		&:hover {
		  td {
			.timebox table tbody tr td {
			  background: #b3dde6;
			}
		  }
		  &.medication-bottom {
			&:hover {
			  background: #fff;
			}
			td {
			  background: transparent;
			}
		  }
		}
		&.meds-header-02,
		&.meds-header-03 {
		  td {
			padding-top: 5px;
			padding-bottom: 5px;
		  }
		}
		&.meds-header-02 {
		  td {
			background: #d2ecf2;
			border-top: 1px solid #ccc;
			toggle-cell();
			&.admin-time-header-odd {
			  text-align: center;
			  background-color: #F0F8FA;
			}
			&.admin-time-header-even {
			  text-align: center;
			  background-color: #D9EEF3;
			}
			&.time-container {
			  padding: 0;
			  min-width: 200px;
			  max-width: 200px;
			  background: #fff;
			  border: 0;
			  .time-container-inner {
				background: #fff;
				display: block;
				width: 100%;
				overflow-x: auto;
				overflow-y: hidden;
				> div {
				  width: 350px;
				  .table {
					margin: 0;
					tr {
					  td {
						border-bottom: 0;
						text-align: center;
						vertical-align: middle;
						padding: 0;
						width: auto;
						background: #f0f8fa;
						border-bottom: 1px solid #9acbd6;
						&:nth-child(even) {
						  background: #d9eef3;
						}
					  }
					  &.seperator {
						td {
						  border-bottom: 0;
						  text-align: center;
						  padding: 0;
						  width: auto;
						  background: #eaf6f9;
						  border-bottom: 1px solid #c9c9c9;
						}
					  }
					}
				  }
				}
			  }
			}
			&.time-container {
			  width: 300px;
			  min-width: auto;
			  max-width: 300px;
			}
		  }
		  &:hover {
			td {
			  background: #d2ecf2;
			  &.admin-time-header-odd {
				background: #f0f8fa;
			  }
			  &.admin-time-header-even {
				background: #d9eef3;
			  }
			  &.time-container {
				background: #fff;
			  }
			}
		  }
		}
		&.meds-header-03 {
		  td {
			font-weight: bold;
			background: #eaf6f9;
			toggle-cell()
		  }
		  &:hover {
			td {
			  background: #eaf6f9;
			}
		  }
		}
	  }
	}
  }

  &.medication-large {
	display: none;
  }
  &.medication-history {
	table {
	  tr {
		th, td {
		  img.medication-changes-danger {
			width: 24px;
			height: 24px;
		  }
		  &:first-child {
			border-right: 0;
		  }
		}
	  }
	}
  }
}

.audit-dosage-period {
	padding-bottom: 10px;
}

#dosage-period-tabs{
	border-left: 1px solid #21a8c6;
	border-right: 1px solid #21a8c6;
}

@media (min-width: $screen-md-min) {
	.medication-list-scrollbar {
		display: block;
		height: 17px;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		.medication-list-scrollbar-width {
			height: 17px;
		}
	}

	.medication-list-container {
		display: block;
		overflow-x: auto;
		overflow-y: hidden;
		table {
			thead,
			tbody {
				tr {
					th,
					td {
						position: static;
						vertical-align: top;
						padding: 10px 5px;
						&.med-view {
							display: table-cell;
						}
						&.large-view {
							display: none;
						}
						&:first-child {
							border-right: 1px solid #dedede;
							width: 40px;
							padding-right: 20px;
							padding-left: 20px;
							text-align: center;
							vertical-align: middle;
						}
						input {
							padding: 0;
							margin: 0;
							width: 18px;
							height: 18px;
							display: block;
						}

					}
					&:hover td {
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			  .pn-medicine-row:hover{
				background-color: #ebf2e6;
			  }
			}
			thead {
				tr {
					th {
						background-color: #dceff3;
					}
					&.meds-header-01 {
						th {
							background: #dceff3;
							padding-bottom: 0;
							border-bottom: 0;
							&:first-child {
								padding-top: 0;
							}
							&.time-header {
								vertical-align: bottom;
								min-width: 250px;
								font-weight: bold;
								padding: 0 0 17px 0;
								position: relative;
								.datepicker-icon {
									margin-left: 5px;
									margin-right: 10px;
								}
								ul.dropdown-menu {
									/* fix for calendar position when page scrolled down */
									top: auto !important;
								}
							}
							&.button-header {
								vertical-align: bottom;
								font-weight: bold;
								padding: 0 0 12px 0;
								.btn-left,
								.btn-right {
									background-position: center center;
									background-size: 30%;
									background-repeat: no-repeat;
									margin: 0;
									bottom: 10px;
									width: 35px;
								}
								.btn-left {
									background-image: url('../../img/icons/arrowLeft.svg');
									right: 40px;
								}
								.btn-right {
									background-image: url('../../img/icons/arrowRight.svg');
									right: 0;
								}
							}
							.btn-group {
								margin: 0 7px 5px 0;
								height: 35px;
								.btn + .btn {
									margin-left: 0;
								}
							}
							.ui-select-container {
								.ui-select-match {
									border-bottom: 3px solid #c9c9c9;
									width: 90px;
									.ui-select-toggle {
										height: 32px;
										padding: 6px 26px 6px 12px;
										box-shadow: none;
										.ui-select-placeholder {
											color: black !important;
											margin: 0;
										}
										.ui-select-match-text {
											margin: 0;
										}
										.caret {
											margin-top: 0;
											cursor: pointer;
										}
									}
								}
								.ui-select-search {
									width: 90px;
									height: 35px;
									border-radius: 0;
									box-shadow: none;
									border: 0;
									border-bottom: 3px solid #c9c9c9;
									padding: 10px 10px 10px 12px;
									font-weight: normal;
								}
								&:after {
									display: none;
								}
							}
							.dropdown-menu {
								th, td {
									padding: 0;
									border: 0;
								}
								th {
									background: #fff;
								}
							}
						}
						&:hover {
							th {
								background: #deeff3;
							}
							.dropdown {
								display: inline-block;
							}
						}
					}
				}
			}
			tbody {
				tr {
					td {
						&.admin-time-odd,
						&.admin-time-even {
							text-align: center;
							img {
								margin: 4px 0 4px 0;
							}
						}
						&.admin-time-odd {
							background-color: #F0F8FA;
						}
						&.admin-time-even {
							background-color: #D9EEF3;
						}
						.timebox {
							background: #fff;
						}
						.timebox table tbody tr td {
							padding: 0;
							border: 0;
							background: #b3dde6;
						}
						.timebox table tbody tr td {
							padding: 0;
							border: 0;
							background: #b3dde6;
						}
					}
					&.medication-bottom {
						td {
							border-bottom: 0;
							height: 18px;
							padding: 0;
							&:first-child {
								border: 0;
							}
						}
					}
					&:hover {
						td {
							.timebox table tbody tr td {
								background: #b3dde6;
							}
						}
						&.medication-bottom {
							&:hover {
								background: #fff;
							}
							td {
								background: transparent;
							}
						}
					}
					&.meds-header-02,
					&.meds-header-03 {
						td {
							padding-top: 5px;
							padding-bottom: 5px;
						}
					}
					&.meds-header-02 {
						td {
							background: #e8e2a1;
							border-top: 1px solid #ccc;
							&.admin-time-header-odd {
								text-align: center;
								background-color: #F0F8FA;
							}
							&.admin-time-header-even {
								text-align: center;
								background-color: #D9EEF3;
							}
							&.time-container {
								padding: 0;
								min-width: 200px;
								max-width: 200px;
								background: #fff;
								border: 0;
								.time-container-inner {
									background: #fff;
									display: block;
									width: 100%;
									overflow-x: auto;
									overflow-y: hidden;
									> div {
										width: 350px;
										.table {
											margin: 0;
											tr {
												td {
													border-bottom: 0;
													text-align: center;
													vertical-align: middle;
													padding: 0;
													width: auto;
													background: #f0f8fa;
													border-bottom: 1px solid #9acbd6;
													&:nth-child(even) {
														background: #d9eef3;
													}
												}
												&.seperator {
													td {
														border-bottom: 0;
														text-align: center;
														padding: 0;
														width: auto;
														background: #f3f0cd;
														border-bottom: 1px solid #c9c9c9;
													}
												}
											}
										}
									}
								}
							}
							&.time-container {
								width: 300px;
								min-width: auto;
								max-width: 300px;
							}
						}
						&:hover {
							td {
								background: #e8e2a1;
								&.admin-time-header-odd {
									background: #f0f8fa;
								}
								&.admin-time-header-even {
									background: #d9eef3;
								}
								&.time-container {
									background: #fff;
								}
							}
						}
					}
					&.meds-header-03 {
						td {
							font-weight: bold;
							background: #f3f0cd;
							toggle-cell();
						}
						&:hover {
							td {
								background: #f3f0cd;
							}
						}
					}
				}
			}
		}

		&.medication-large {
			display: none;
		}
		&.medication-history {
			table {
				tr {
					th, td {
						img.medication-changes-danger {
							width: 24px;
							height: 24px;
						}
						&:first-child {
							border-right: 0;
						}
					}
				}
			}
		}
	}

  	.dispensing-list-container {
	  display: block;
	  overflow-x: visible;
	  overflow-y: visible;
	  padding 10px 20px 10px 20px;
	  > div:first-child {
		border: 1px solid #b0b0b0;
	  }
	  table {
		tbody {
		  tr {
			th,
			td {
			  position: static;
			  vertical-align: top;
			  padding: 5px 20px;
			  &.med-view {
				display: table-cell;
			  }
			  &.large-view {
				display: none;
			  }
			  &:first-child {
				width: 40px;
				padding-right: 10px;
				padding-left: 20px;
				vertical-align: middle;
			  }
			}
		  }
		}
		tbody {
		  tr {
			&.meds-header-02,
			&.meds-header-03 {
			  td {
				padding-top: 5px;
				padding-bottom: 5px;
			  }
			}
			&.meds-header-02 {
			  td {
				font-weight: bold;
				background: #e8e2a1;
			  }
			}
			&.meds-header-03 {
			  td {
				font-weight: bold;
				background: #f3f0cd;
			  }
			}
		  }
		}
	  }

	  &.medication-large {
		display: none;
	  }

	  .btn-add-border {
		border-top: 1px solid #c9c9c9 !important;
		border-right: 1px solid #c9c9c9 !important;
		border-left: 1px solid #c9c9c9 !important;
	  }

	  .dropdown {
		margin-bottom: 0;
		.dropdown-toggle {
		  border-top: 0;
		  border-right: 0;
		  border-left: 1px solid #c9c9c9 !important;
		}
		.dropdown-menu:hover {
		  background: #ebf2e6;
		}
	  }
	}
}

@media (min-width: $screen-lg-min) {
	.medication-list-container {
		table {
			thead,
			tbody {
				tr {
					th,
					td {
						&.med-view {
							display: none;
						}
						&.large-view {
							display: table-cell;
						}
					}
				}
			}
		}
	}
	.dispensing-list-container {
	  table {
		thead,
		tbody {
		  tr {
			th,
			td {
			  &.med-view {
				display: none;
			  }
			  &.large-view {
				display: table-cell;
			  }
			}
		  }
		}
	  }
	}
}

.dispensing-overview-group {
  padding: 3px 9px 3px 20px;
  background-color: #E7E7E7;
  border: 0;
  border-bottom: 1px solid #b0b0b0;
  min-height: 41px;
  margin-bottom: 0;
  display: block;
  width: 100%;
  position: relative;
  h3 {
	line-height: 32px;
	margin: 1px 20px 0 0;
	font-size: 14px;
  }
  .btn-group {
	padding-right: 4px;
  }
}

.dispensing-time-toolbar {
  .form-inline {
	margin-bottom: 5px;
  }
  button {
	margin-left: 10px;
  }
}

.dispensing-period-change
	.dispensation-medicine-toolbar
		background-color: white
	.current-label
		padding: 0 0 0 10px
	.current-period
		padding: 10px
		margin-top: 20px
	.times
		.times-container
			.form-group
				padding: 10px !important

.dispensation-medicine-toolbar
	background-color: #e6e6e6
	min-height: 50px
	font-weight bold
	position: sticky
	position: -webkit-sticky
	top: 0
	z-index: 10
	overflow: hidden

	h3
		margin: 10px 10px 3px 0
		padding-left: 20px
		font-size: 1em
		color: #000;
		span
			a
				color: black
				text-decoration: underline

.dispensation-medicine-remarks
	padding-top: 5px
	padding-left: 20px

.dispensing-values-container {
  padding: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  table {
	tbody {
	  tr {
		td {
		  background-clip: padding-box;
		  border-right-style: solid;
		  border-right-width: 1px;
		  border-right-color: white;
		  border-bottom-style: solid;
		  border-bottom-width: 5px;
		  border-bottom-color: white;

		  &.admin-time-odd {
			background-color: #F0F8FA;
		  }
		  &.admin-time-even {
			background-color: #D9EEF3;
		  }
		  &.selected-cell {
			background-color: #c0e0c0 !important;
			transition: background .3s;
		  }
		  &.day-column {
			text-transform: capitalize;
		  }
		  &.value-field {
			.icon-box {
			  text-align: center;
			  display: inline-block;
			  margin: 0 5px 0 5px;
			  .icon {
				width: 18px;
				height: 18px;
				display: inline-block;
				padding-top: 4px;
			  }
			}
		  }
		  &:first-child {
			width: 100px;
		  }
		}
		&.time-header {
		  padding-top: 20px;
		  font-weight: bold;
		  background-color: #e8e2a1;
		  border-bottom-style: solid;
		  border-bottom-width: 20px;
		  border-bottom-color: white;
		}
	  }
	}
  }

}

.dispensing-warning {
  margin-bottom: 10px;
}

.risk-drug-warning-icon {
	width: 24px;
	height: 24px;
	padding-bottom: 5px;
}

.pill-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  top: 2px;
  background-size: contain;
  position: relative;
  margin: 0 5px 0 0;
  background-repeat: no-repeat;
  background-image: url(../../img/icons/pill_icon.png)
}


.move-dosage-days-icon {
	width:40px;
	float: left;
	cursor: pointer
}

@media (min-width: $screen-md-min){
	.dispensationFilter {
		.ui-select-multiple {
			width: 650px !important }
	}
}
.dispensationFilter {
	.ui-select-multiple {
		.ui-select-match {
			.ui-select-match-item {
				border: 1px solid #ccc !important
				border-radius: 4px
			}
		}
	}
}

.resultFilter{
	padding-top: 7px
}

.pn-medicine-row ul{
  margin: 0;
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 0;
  box-shadow: 0 0 0;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .pn-details-dosage-unit-form-control, .pn-edit-dosage-unit-form-control{
	width: 250px !important;
  }
}

.pn-medicine-row ul li{
  border-bottom: 1px solid #ccc;
  padding: 8px 25px 8px 8px !important;
  cursor: pointer;
  font-weight: 100;
}

.pn-medicine-row ul li:hover{
  background: #ebf2e6;
}

.dispensation-list .dropdown-menu{
  width: 212px !important;
}

.dispensation-toggle-menu
	margin-bottom: 0 !important

.dispensation-toggle-button
	margin-bottom: 5px !important


.pn-list-dropdown-left {
  .dropdown-menu {
	 left: -180px !important;
  }
}

nx-timepicker
	input[type="text"]
		display: block
		width: 64px !important
		height: 34px
		padding: 6px 12px !important
		font-size: 14px
		line-height: 1.428571429
		color: #555
		background-color: #fff
		background-image: none
		border: 1px solid #ccc
		border-radius: 4px
		box-shadow: inset 0 1px 1px rgba(0,0,0,0.075)
		transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s

.futureEffectuation
	margin: 10px
	.select-effectuation
		margin-top: 20px
		margin-bottom: 20px
		.select-effectuation-list
			overflow: auto
			display: block
			border: 1px solid #ddd
			max-height: 210px
			-webkit-overflow-scrolling: touch
			.select-effectuation-element
				border-bottom: 1px solid #ddd !important
				padding: 10px !important
				font-weight: 100
		.select-effectuation-list-header
			margin-top: 10px
	.effectiveFromDateTime
		padding-left: 0 !important
		display: inline-block
		width: 100%
		label
			padding-right: 5px !important
			display: inline-block
		.effectiveFromDate
			display: inline-block
		.effectiveFromTime
			display: inline-block

.effectiveFrom
	display: inline-block
	label
		padding-right: 5px !important
		display: inline-block

.setNewMaxQuantity
	#warningText
		margin-bottom: 40px
	.table
		th
			font-weight: bold;
		.whiteRow
			background-color: white
		.blueRow1
			background-color: #d9eef3
		.blueRow2
			background-color: #f0f8fa



.css-pill-icon
	color: #585858
	margin: 2px
	width: 14px
	height: 14px
	position: relative
	border: solid 1px currentColor
	border-radius: 50%
	background-color: rgb(251, 231, 231)
	box-shadow: 0.5px 1px

.css-pill-half-icon
	color: #585858
	margin: 2px
	width: 8px
	height: 14px
	left: 3px
	position: relative
	border: solid 1px currentColor
	border-radius: 0 7px 7px 0
	background-color: rgb(251, 231, 231)
	box-shadow: 0.5px 1px

.css-pill-quarter-icon
	color: #585858
	margin: 2px
	width: 8px
	height: 9px
	left: 3px
	position: relative
	border: solid 1px currentColor
	border-radius: 0 8px 0 0
	background-color: rgb(251, 231, 231)
	box-shadow: 0.5px 1px

.dispensation-normal-font
	font-weight: normal

.dispensation-list-tooltip
	color: #9e9e9e
	white-space: nowrap
	display: inline
	max-width: 95%
	overflow: hidden

.dispensation-checkbox
	width: 61px !important

.pn-medicine-row-bold
	font-weight: bold

.pn-medicine-row-normal
	font-weight: normal !important

.dispensation-vertical-alignment
	vertical-align: middle

.dispensation-vertical-alignment-normal
	@extends .dispensation-vertical-alignment, .dispensation-normal-font

.dispensation-vertical-alignment-clickable
	@extends .dispensation-vertical-alignment
	cursor: pointer

.dispensation-vertical-alignment-nowrap
	@extends .dispensation-vertical-alignment
	white-space: nowrap

.dispensation-vertical-alignment-margin
	@extends .dispensation-vertical-alignment
	margin-right: 20px

.dispensation-padding-top
	padding-top: 5px

.dispensation-information
	@extends .dispensation-padding-top
	display: inline-flex
	padding-right: 10px

.dispensation-information-clickable
	@extends .dispensation-information
	cursor: pointer
	min-height: 30px
	text-wrap: pretty

.dispensation-workflow
	@extends .dispensation-padding-top
	margin-right: 20px
	width: 120px

.dispensation-workflow-container
	display: inline-flex
	min-width: 170px

.dispensing-button-dropdown
	.dropdown-menu
		left: -213px !important
		padding: 10px

.pn-medicine-dropdown
	right: 10px
	border-left: 1px solid #ccc
	width: 31px
	height: 30px

.pn-medicine-dropdown-minwidth
	@extends .pn-medicine-dropdown
	min-width: 32px

.tooltip-icon-info
	width: 17px
	height: 17px
	display: inline-block
	position: relative
	top: 4px
	left: 2px
	background-image: url("../../img/icons/Information.svg")
	cursor: pointer
	background-size: 100% 100%

.dispensation-absent
	color: #9e9e9e

.dispensation-absent-override
	button
		color: black

.meds-no-bottom-margin
	margin-bottom: 0

#dispensing-medication-card
	> div:first-child {
		border: 0
	}
	padding-left: 20px
	padding-right: 20px

.dispensing-medication-card-scroll
	border: 1px solid #dedede !important
	border-top: 0
	min-height: 15vh
	max-height: 49vh
	overflow-y: scroll

.css-badge-icon
	color: #585858
	margin: 0 2px 2px 20px
	width: 16px
	height: 16px
	position: relative
	border: solid 1px white
	border-radius: 50%
	background-color: white
	box-shadow: 0.5px 1px
	text-align: center
	font-size: smaller
	font-weight: 600

.basket-icon
	width: 30px
	height: 30px
	display: inline-block
	position: relative
	left: 2px
	background-image: url('../../img/icons/basket-icon.png')
	cursor: pointer
	background-size: 100% 100%
	&.empty
		opacity 0.4

.fmk-basket-td
	vertical-align: top !important

.fmk-basket-input
	label
		top: 0 !important
		width: 100% !important
		float: none !important
		font-weight: normal !important

	input
		width: 100% !important

	.ui-select-bootstrap
		height: none !important

	.ui-select-container
		div
			span
				width: 100% !important

.fmk-basket-input-multi
	label
		top: 0 !important
		width: auto !important
		float: none !important
		font-weight: normal !important

	input
		width: auto !important

	.ui-select-multiple
		width: auto !important

.basket-header
	div
		margin: 2px 7px 2px 0
		position: relative
		display: inline-block
		vertical-align: middle

#bulkDispensations {
  input[type="number"] {
	width: 20%;
  }
  span {
	padding-left: 6px;
	padding-right: 6px;
  }
}

.vka-no-subsequent-dosage
	width: 100%;
	color: #ff5353; // copied from alert-danger style
	font-weight: bold;
	font-style: italic;
	span {
		float: left;
		padding-left: 15px;
	}

.expiration-date-inline-icon {
	margin-bottom: -5px;
	margin-left: -10px;
}
