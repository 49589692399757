@require "bootstrap/index";
@require "../../../node_modules/font-awesome-stylus/stylus/font-awesome";
@require "core/variables";
@require "core/icons";
@require "core/sticky";
@require "overrides/bootstrap_overrides";
@require "overrides/tinymce_overrides";
@require "templates/template_elements";
@require "core/nxelements";
@require "core/nxelements";
@require "core/login";
@require "core/patient";
@require "core/medicines";
@require "core/twocolumnllist";
@require "core/fileicons";
@require "core/messages";
@require "flowpanel";
@require "dashboard";
@require "audit";
@require "actionplans";
@require "reports";
@require "filters";
@require "typeahead";
@require "measurement";

//html {
//	-ms-overflow-style: scrollbar;
//}
//
//body {
//	overflow-x: hidden;
//	overflow-y: auto;
//	background: #fff;
//	font-family: "interface", sans-serif !important;
//	font-size: 14px !important;
//	color: $default-text-color !important;
//	.body-sub {
//		min-height: 100vh;
//		overflow: hidden;
//	}
//}

// this fixes bootstrap issue which adds padding-right each time a large modal is shown
body {
  padding-right: 0px !important;
}

td, th {
	font-family: "interface", sans-serif !important;
	font-size: 14px !important;
}

h3 {
	margin: 0;
	float: left;
	color: $title-color;
	font-size: 18px;
	line-height: 30px;
	font-weight: 700;
	letter-spacing: .02em;
	text-transform: none;
}

input[type="radio"],
input[type="checkbox"] {
	width: 18px;
	height: 18px;
	margin: 1px 0 0;
}

.hide-on-portrait,
.hide-on-handheld {
	display: none;
}

.hide-on-desktop {
	display: table-cell;
}

@media (min-width: $screen-sm-min) {
	.hide-on-handheld {
		display: table-cell;
	}

	.hide-on-desktop {
		display: none;
	}
}

@media (min-width: $screen-md-min) {
	.hide-on-portrait {
		display: table-cell;
	}
}

.ios-safari-tap-fix {
	color #addfe9
	height 0
}

.form-container {
	padding: 10px 10px 10px 45px;
}

.css-form input.ng-invalid.ng-touched {
	background-color: #FA787E;
}

.css-form input.ng-valid.ng-touched {
	background-color: #78FA89;
}

.css_require {
	position: fixed;
	right: 25px;
	bottom: 25px;
	border: 1px solid #000;
	background: #ccc;
	padding: 10px;
	display: none;
	h3 {
		margin-top: 0;
	}
	hr {
		background: #000;
	}
	p {
		margin: 10px 0 0;
		&:first-child {
			margin-top: 0;
		}
	}
}

.row {
	margin-top: 0;
	[class*="col-"] & {
		margin-right: 0;
		margin-left: 0;
	}
}

[class*="col-"] {
	padding-left: 0;
	padding-right: 0;
}

@media (min-width: $screen-md-min) {
	.panel-top {
		width: 300%;
		height: 31px;
		position: absolute;
		background-color: #addfe9;
		z-index: -1;
	}
}

.toast-box {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10000;
  padding: 10px;
  border-radius: 4px;
  background-color: #31708f;
  border: #31708f solid 1px;
  width: 400px;
  color: #d9edf7;
  span.close {
	color: #d9edf7;
	text-shadow: none;
	opacity: 1;
  }
  span.title {
	font-weight: bold;
  }
  div.msg {
	cursor: pointer;
	margin-top: 10px;
	&:hover {
	  text-decoration: underline;
	}
  }
}

.panels {
	margin-top: 0;
	font-size: 14px;
	.panel-header {
		min-height: 46px;
		background: #deeff3;
		border-bottom: 1px solid #ccc;
		padding: 5px 20px;
		border-right: 1px solid #ccc;
		.btn-group {
			height: 35px;
			.ui-select-match-text,
			.ui-select-placeholder {
				margin: 0;
			}
			.ui-select-container > .rbac-select-professional {
				width: auto;
			}
			.btn + .btn {
				margin: 0;
			}
			.ui-select-no-choice {
			  	margin-top: 0px;
			}
		}
		.dropdown {
			display: inline-block;
		}
	}
	[class*="col-"] {
		margin: 0;
		padding-left: 0;
		padding-right: 0;
		height: 100%;
		&.col-no-margin {
			margin: 0;
		}
		&.page-header {
			margin-bottom: 0;
		}
		+ div {
			height: 100%;
		}
		.tree-container,
		.list-container {
			@media (min-width: $screen-md-min) {
				padding-bottom: 150px;
			}
			li {
				.tree-row {
					a {
						color: $default-text-color;
					}
					&:hover {
						background: $item-hover-color;
					}
					&.tree-selected {
						background: $item-selected-color;
					}
					> div > div > span > span:hover {
						text-decoration: underline;
					}
				}
			}
			.input-container {
				position: relative;
				input {
					margin: 0;
					position: absolute;
					top: 14px;
				}
			}
			table {
				margin-bottom: 0;
			}
			&.no-click {
				.tree-row,
				.tree-label {
					cursor: default;
				}
			}
			.list-content {
				span,
				a {
					position: relative;
					top: -5px;
				}
			}
		}
	}
}

.table {
	tbody,
	thead {
		tr {
			th {
				border-bottom: 1px solid #ccc;
				padding: 5px 20px;
			}
			td {
				padding: 8px 20px;
				position: relative;
				border-top: 0;
				border-bottom: 1px solid #ddd;
				&.has-icon {
					span {
						float: left;
					}
				}
			}
		}
	}
	&.hasDropdown {
		tbody,
		thead {
			tr {
				td {
					padding-right: 51px;
					padding-bottom: 7px;
					> div {
						> .list-content {
							float: left;
							max-width: 80%;
							overflow: hidden;
							height: 27px;
							+ .btn-group {
								position: absolute;
								right: 10px;
								top: 6px;
								left: auto;
							}
						}
					}
				}
			}
		}
	}
	&.table-data {
		table-layout: fixed;
		margin: 0;
		thead {
			tr {
				th {
					vertical-align: middle;
					&:last-child {
						text-align: center;
						padding-right: 20px;
					}
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 5px 10px;
					div {
						padding: 0 0 10px;
						font-weight: bold;
						.tag {
							margin: 0 0 0 5px;
							border: 1px solid #cccccc;
							padding: 2px 5px;
							border-radius: 3px;
							color: $default-text-color;
							background: #e6e6e6;
							position: relative;
							top: 0;
							font-weight: normal;
							&:first-child {
								margin-left: 10px;
							}
						}
					}
					table {
						table-layout: fixed;
						tbody {
							tr {
								td {
								 	vertical-align : text-top;
									padding: 0 0 5px 10px;
									display: table-cell;
									word-break: break-word;
									border-bottom: 0;
									&:first-child {
										padding-left: 0;
									}
									&:nth-child(1) {
										width: 25%;
									}
									&:nth-child(3) {
										width: 20%;
									}
									&:nth-child(4) {
										width: 15%;
										text-align: right;
									}
									span {
										color: #9e9e9e;
									  	small {
										  font-size: 14px;
										}
										span {
										  color: #000000;
										}
									}
								}
							}
						}
					}
				}
				&.table-data-header {
					td {
						background: #f3f0cd;
						.toggle-label {
							display: block;
							width: 100%;
							height: 20px;
							overflow: hidden;
							margin: 0;
							background-image: url("../img/icons/arrowRight.svg");
							background-repeat: no-repeat;
							background-position: center;
							background-size: 30% 60%;
							cursor: pointer;
						}
						.needs-attention {
							width: 15px;
							height: 15px;
							display: inline-block;
							background-size: contain;
							margin: 0 5px 0 5px;
							position: relative;
							background-repeat: no-repeat;
							background-image: url("../img/icons/Danger.svg");
						}
						a {
							color: #3782c4;
						}
						small {
							font-size: 14px;
							color: #9e9e9e;
							margin: 0 0 0 10px;
						}
						&:first-child {
							border-right: 1px solid #dedede;
							width: 58px;
						}
					}
				}
			}
		}
		&.data-table-alternative {
			border-top: 1px solid #749099;
			tr {
				td:nth-child(even) {
					background: #d9eef3;
				}
				th:nth-child(even) {
					background: #d9eef3;
				}
				td:nth-child(odd) {
					background: #f0f8fa;
				}
				th:nth-child(odd) {
					background: #f0f8fa;
				}
				td:first-child,
				th:first-child {
					background: #fff;
					word-break: break-all;
				}
				&:hover {
					.dropdown {
						display: inline-block;
					}
				}
			}
			td, th {
				padding: 5px 10px;
			}
			@media (max-width: $screen-md-max) {
				.field-label {
					width: 100px;
				}

				td, th {
					padding: 5px 5px;
				}
			}
		}
		&.data-table-alternative-last-column-normal {
		  tr {
			td:nth-child(even) {
			  background: #d9eef3;
			}
			th:nth-child(even) {
			  background: #d9eef3;
			}
			td:nth-child(odd) {
			  background: #f0f8fa;
			}
			th:nth-child(odd) {
			  background: #f0f8fa;
			}
			td:first-child,
			th:first-child {
			  background: #fff;
			  word-break: break-all;
			}
			&:hover {
			  .dropdown {
				display: inline-block;
			  }
			}
		  }
		  td, th {
			padding: 5px 10px;
		  }
		  @media (max-width: $screen-md-max) {
			.field-label {
			  width: 100px;
			}

			td, th {
			  padding: 5px 5px;
			}
		  }
		}
		&.toggle-open {
			tbody {
				tr {
					&.table-data-header {
						td {
							.toggle-label {
								background-image: url("../img/icons/arrowDown.svg");
								background-size: 60% 30%;
							}
						}
					}
				}
			}
		}
	}
}

.patient-data-vertical-list {
  &.content {
	padding: 10px;
	border-bottom: 1px solid #DDDDDD;
	&:hover {
	  background-color: #ebf2e6;
	  cursor: pointer;
	}
	&:last-child {
	  border-bottom: none;
	}

  }

  .action-dropdown {
	border : 1px solid #ccc;
	.dropdown-menu {
	  margin: 0;
	  border-radius: 0;
	  padding: 0;
	  box-shadow: 0 0 0;
	  white-space: nowrap;
	}
	li {
	  font-style: normal;
	  border-bottom: 1px solid #ccc;
	  padding: 8px 25px 8px 8px !important;
	  cursor: pointer;
	  &:hover {
		background: $item-hover-color;
	  }
	}
  }
  .action-link{
	color: #337ab7 !important;
	text-decoration: none !important;
  }
  .action-link:hover{
	text-decoration: underline !important;
  }
  .data-name {
	font-weight: bold;
	text-decoration: underline;
	margin-bottom: 10px;
	margin-right 10px;
  }
  .tags-row {
	display: flex;
	flex-wrap: wrap;
	.tag {
	  margin-right: 5px;
	}
  }
  .data-row-name {
	font-weight: bold;
  }
  .data-row {
	margin-bottom: 10px;
  }
  .simple-references {
	display: inline-flex;
	white-space: nowrap;
	flex-wrap: wrap;
	.reference {
	  margin-right: 7px;
	}
  }

}

@media (min-width: $screen-sm-min) {
	.table {
		&.hasDropdown {
			tbody,
			thead {
				tr {
					td {
						/*padding-bottom: 4px;*/
						> div {
							> .list-content {
								max-width: 85%;
								height: 27px;
								overflow: hidden;
								word-break: break-all;
							}
						}
					}
					/*&:hover td {
						padding-bottom: 4px;
					}*/
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.table {
		&.hasDropdown {
			tbody,
			thead {
				tr {
					td {
						padding-right: 10px;
						padding-bottom: 7px;
						> div {
							> .list-content {
								+ .btn-group {
									position: relative;
									top: -2px;
									left: 10px;
									float: left;
									right: auto;
									display: none;
								}
							}
						}
					}
					&.dropdown-open td {
						/*padding-bottom: 4px;*/
						> div {
							> .list-content {
								+ .btn-group {
									display: block;
								}
							}
						}
					}
					/*&.dropdown-open td {
						padding-bottom: 4px;
						.btn-group {
							display: block;
						}
					}*/
				}
			}
		}
	}
}

nx-react-citizen-list > div {
  margin-left: -15px;
  margin-right: -15px;
}

.data-toolbar {
	background: #dceff3;
	padding: 10px 20px 5px;
    width: 100%;
	display: none
	.form-group {
		margin: 0;
		&.form-inline {
			.toolbar-container {
				float: left;
				margin: 0 10px 5px 0;
				> span {
					position: relative;
					top: 2px;
				}
				.ui-select-container {
					width: 250px;
					&.dropdown {
						> .ui-select-match {
							> .btn {
								height: 34px;
								border: 1px solid #ccc;
								border-radius: 4px;
								width: 100%;
								margin: 0;
								.ui-select-match-text {
									margin: 0;
								  	text-overflow: ellipsis;
								  	white-space: nowrap;
								  	overflow: hidden;
								}
							}
						}
					}
					&.ui-select-multiple {
						padding-top: 5px;
						padding-bottom: 1px;
						.ui-select-match {
							+ input {
								margin-left: 5px;
							}
						}
						.ui-select-match-item {
							border: 1px solid #ccc;
							border-radius: 3px;
							color: $default-text-color;
							background: #e6e6e6;
							.ui-select-match-close {
								position: relative;
								top: 2px;
							}
						}
					}
					.ui-select-placeholder {
						margin: 0;
					}
					input {
						width: 100% !important;
					}
				}
				input {
					display: inline-block;
					width: auto;
					vertical-align: middle;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.data-toolbar {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
}

.nav {
	padding: 0 10px;
	background-color: #addfe9;
	&.nav-tabs {
		border: 0;
		> li {
			margin-left: 0;
			background-color: rgba(255, 255, 255, 0.75);
			border-top: 1px solid #91bbc4;
			border-right: 1px solid #91bbc4;
			border-bottom: 0;
			border-left: 0;
			&:first-child {
				border-left: 1px solid #91bbc4;
			}
			> a {
				top: 0;
			}
			&.active {
				a {
					background: #deeff3;
					border: 0;
				}
			}
		}
	}
	li {
		margin-bottom: 0;
		a {
			border: 0;
			padding: 5px 10px;
			margin: 0;
			font-size: 14px;
			border-radius: 0;
			color: $default-text-color;
			&:hover,
			&:focus {
				background: #fff;
				color: #000;
			}
		}
		&.active {
			a {
				border: 0;
				color: #000;
				&:hover,
				&:focus {
					border-top: 0;
					border-left: 0;
				}
			}
		}
	}
}

.page-header {
	padding: 7px 10px 0;
	background-color: #addfe9;
	border: 0;
	border-bottom: 1px solid #749099;
	min-height: 50px;
	margin-bottom: 0;
	&.has-pagination {
		position: relative;
		padding-right: 200px;
		.pagination-container {
			width: 300px;
			height: 35px;
			position: absolute;
			top: 5px;
			right: 20px;
			margin: 0;
			nx-pagination,
			nx-pagination-pagenumbers,
			nx-pagination-previous-next {
				position: absolute;
				right: 0;
			}
		}
	}
	&.has-tabs {
		border-bottom: 0;
	}
	h3 {
		margin: 2px 20px 3px 0;
		padding-left: 10px;
	}
	.input[type="radio"] {
		opacity:0.01;
		z-index:100;
	}
	input[type="radio"] {
		display:none;
	}
	.btn-container {
		.btn-group {
			margin-bottom: 2px;
		}
	}

	.radioOnly-true{
		background : #e9e9e9;
	}
	.radioFuture-true{
		background : #e9e9e9;
	}
	.radioAsk-true{
		background : #e9e9e9;
	}
	.single-check{
		margin: 0px 20px 3px 0;
		padding-left: 10px;
		margin-bottom: 2px;
		label {
			padding-left: 15px;
			text-indent: -15px;
			display: contents;

		}
		input {
			width: 13px;
			height: 13px;
			padding: 0;
			margin:0;
			vertical-align: bottom;
			position: relative;
			top: -1px;
		}
	}
}

/*&:first-child {
	td {
		border-top: 0;
	}
}*/

treecontrol,
.treecontrol {
	li > div > div {
		padding: 8px 0 6px 10px;
		.tree-label i + div {
			max-width: 57%;
		}
	}
	treeitem > ul > li {
		> div > div {
			padding-left: 30px;
			.tree-label i + div {
				max-width: 56%;
			}
		}
		treeitem > ul > li {
			> div > div {
				padding-left: 50px;
				.tree-label i + div {
					max-width: 55%;
				}
			}
			treeitem > ul > li {
				> div > div {
					padding-left: 70px;
					.tree-label i + div {
						max-width: 54%;
					}
				}
				treeitem > ul > li {
					> div > div {
						padding-left: 90px;
						.tree-label i + div {
							max-width: 53%;
						}
					}
					treeitem > ul > li {
						> div > div {
							padding-left: 110px;
							.tree-label i + div {
								max-width: 52%;
							}
						}
						treeitem > ul > li {
							> div > div {
								padding-left: 130px;
								.tree-label i + div {
									max-width: 51%;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	treecontrol,
	.treecontrol {
		.panels & {
			li > div > div {
				.tree-label i + div {
					overflow: hidden;
					top: 0;
				}
			}
			treeitem > ul > li {
				> div > div {
					.tree-label i + div {
						overflow: hidden;
						top: 0;
					}
				}
				treeitem > ul > li {
					> div > div {
						.tree-label i + div {
							overflow: hidden;
							top: 0;
						}
					}
					treeitem > ul > li {
						> div > div {
							.tree-label i + div {
								overflow: hidden;
								top: 0;
							}
						}
						treeitem > ul > li {
							> div > div {
								.tree-label i + div {
									overflow: hidden;
									top: 0;
								}
							}
							treeitem > ul > li {
								> div > div {
									.tree-label i + div {
										overflow: hidden;
										top: 0;
									}
								}
								treeitem > ul > li {
									> div > div {
										.tree-label i + div {
											overflow: hidden;
											top: 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	treecontrol,
	.treecontrol {
		li > div > div {
			.tree-label i + div {
				max-width: 65%;
			}
		}
		treeitem > ul > li {
			> div > div {
				.tree-label i + div {
					max-width: 64%;
				}
			}
			treeitem > ul > li {
				> div > div {
					.tree-label i + div {
						max-width: 63%;
					}
				}
				treeitem > ul > li {
					> div > div {
						.tree-label i + div {
							max-width: 62%;
						}
					}
					treeitem > ul > li {
						> div > div {
							.tree-label i + div {
								max-width: 61%;
							}
						}
						treeitem > ul > li {
							> div > div {
								.tree-label i + div {
									max-width: 60%;
								}
							}
							treeitem > ul > li {
								> div > div {
									.tree-label i + div {
										max-width: 59%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

i.remove-icon {
	top: 5px;
	right: 5px;
	width: 35px;
	height: 35px;
	cursor: pointer;
	display: inline-block;
	background-image: url("../img/icons/icon_remove_colorful.svg");
	background-position: center center;
	background-size: 50%;
	background-repeat: no-repeat;
}

i.remove-patient-img {
  position: relative;
  top: 40px;
  left: 2px;
  width: 30px;
  height: 30px;
}

i.add-icon {
	background-image: url("../img/icons/icon_add_colorful.svg");
	background-size: 16px 16px;
	background-repeat: no-repeat;
	vertical-align: middle;
	display: inline-block;
	width: 20px;
	height: 18px;
}

i.tree-icon-none {
	width: 0 !important;
	margin: 0 !important;
	background: none !important;
}

i.tree-users-icon {
	padding: 1px 10px;
	margin: 0 5px 0 10px;
	background: url("../img/icons/Home-4.svg") no-repeat;
}

/* Bootstrap overrides */
.css-form {
	padding: 0 15px;
}

@require "core/treecontrol";
@require "core/fastpatientpathway";
@require "core/fastorganizationtree";
@require "prototype";

.prototype .button.buttonless.hide-on-handheld {
	display: none;
}

.prototype .button.buttonless.btnlast.btnarrowdown.hide-on-desktop {
	display: block;
	float: left;
}

@media (min-width: $screen-sm-min) {
	.prototype .button.buttonless.hide-on-handheld {
		display: block;
	}

	.prototype .button.buttonless.btnlast.btnarrowdown.hide-on-desktop {
		display: none;
	}
}

.ui-select-match-item.btn {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.breakpoints {
	position: fixed;
	right: 10px;
	bottom: 10px;
	border: 1px solid #000;
	padding: 10px;
	z-index: 10;
	background-color: #fff;
	.xxs {
		display: block;
	}
	.xs, .sm, .md, .lg {
		display: none;
	}
	@media (min-width: $screen-xs-min) {
		.xxs {
			display: none;
		}

		.xs {
			display: block;
		}
	}
	@media (min-width: $screen-sm-min) {
		.xs {
			display: none;
		}

		.sm {
			display: block;
		}
	}
	@media (min-width: $screen-md-min) {
		.sm {
			display: none;
		}

		.md {
			display: block;
		}
	}
	@media (min-width: $screen-lg-min) {
		.md {
			display: none;
		}

		.lg {
			display: block;
		}
	}
}

a.action-link {
	cursor pointer;
}

.slider-active {
	overflow: hidden;
}

.slider {
	position: fixed;
	height: 100%;
	right: -100%;
	top: 0;
	transition: right .3s;
	overflow: hidden;
	width: 100%;
	z-index: 100;
	-webkit-overflow-scrolling: touch;
	.slider-inner {
		width: 90%;
		border-left: 5px solid #21a8c6;
		background: #fff;
		float: right;
	}
	.slider-active & {
		right: 0;
		overflow-y: auto;
	}
}

@media (min-width: $screen-md-min) {
	.slider-inner {
		width: 80%;
	}
}

@keyframes loading-spinner {
	100% {
		transform: rotate(360deg);
	}
}

/* :not(:required) hides this rule from IE9 and below */
.loading-spinner:not(:required) {
	animation: loading-spinner 1500ms infinite linear;
	border-radius: 0.5em;
	box-shadow: rgba(0, 0, 51, 0.3) 1.5em 0 0 0, rgba(0, 0, 51, 0.3) 1.1em 1.1em 0 0, rgba(0, 0, 51, 0.3) 0 1.5em 0 0, rgba(0, 0, 51, 0.3) -1.1em 1.1em 0 0, rgba(0, 0, 51, 0.3) -1.5em 0 0 0, rgba(0, 0, 51, 0.3) -1.1em -1.1em 0 0, rgba(0, 0, 51, 0.3) 0 -1.5em 0 0, rgba(0, 0, 51, 0.3) 1.1em -1.1em 0 0;
	justify-content center;
	font-size: 4px;
	width: 1em;
	height: 1em;
	overflow: hidden;
	text-indent: 100%;
	margin: 15px auto;
}

.loading-spinner.loading-hidden {
	display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="text"]:disabled {
	background-color: #eee !important;
}

textarea[readonly] {
  background-color: #eee !important;
  -ms-overflow-style: none;
}

input[type="text"][readonly] {
  background-color: #eee !important;
}

input[type="number"][readonly] {
  background-color: #eee !important;
}

input[type="number"]:disabled {
	background-color: #eee !important;
}

input[type="email"]:disabled {
	background-color: #eee !important;
}

textarea:disabled {
	background-color: #eee !important;
}

.color-pick {
	width: 20px;
	height: 20px;
	display: inline-block;
}

.plain-text-value {
	margin-bottom: 0;
	display: inline-block;
	vertical-align: middle;
	padding: 7px 10px;
}

.pagination {
	li {
		a {
			padding: 0;
			margin: 0;
			border-radius: 0;
			border-top: 0;
			border-right: 0;
			border-bottom: 1px solid #c9c9c9;
			border-left: 0;
			height: 32px;
			width: 40px;
			margin-left: 5px;
			font-size: 0;
			background-size: 8px 16px;
			background-position: center center;
			background-repeat: no-repeat;
		}
		&:first-child {
			> a,
			> span {
				border-radius: 0;
			}
			> a {
				margin-left: 0;
				background-image: url("../img/icons/arrowLeft.svg");
			}
		}
		&:last-child {
			> a,
			> span {
				border-radius: 0;
			}
			> a {
				background-image: url("../img/icons/arrowRight.svg");
			}
		}
	}
	> .disabled {
		> span,
		> a {
			cursor: default;
			&:hover,
			&:focus {
				cursor: default;
			}
		}
	}
}

.pagination-container {
	text-align: center;
	justify-content: center;
	margin: 20px;
	li {
		a {
			border-top: 1px solid #c9c9c9;
			border-right: 1px solid #c9c9c9;
			border-left: 1px solid #c9c9c9;
		}
	}
	a {
		margin-left: 5px;
		color: #3782c4;
		&:hover {
			color: #2f6ea7;
			text-decoration: underline;
		}
	}
}

.sort-order {
	margin: 0;
	padding: 10px 20px 0;
	.list-group {
		background: #f9f9f9;
		border: 1px solid #dbdbdb;
		padding: 10px 10px 5px 10px;
		min-height: 35px;
		margin-bottom: 10px;
		.list-group-item {
			margin: 0 0 5px 0;
			padding: 5px 10px;
			cursor: move;
			min-height: 47px;
			border: 1px solid #dbdbdb;
			background-color: #fff;
			.sort-table {
				width: 100%;
				padding: 0 45px 0 0;
				> span {
					display: table;
					> span {
						display: table-cell;
						min-height: inherit;
						> span {
							vertical-align: middle;
							> div {
								vertical-align: middle;
								display: inline-block;
							}
						}

					}
				}
			}
			.sort-remove {
				margin-left: 20px;
				position: absolute;
				top: 5px;
				right: 5px;
				width: 35px;
				height: 35px;
				cursor: pointer;
				background-image: url("../img/icons/icon_remove_colorful.svg");
				background-position: center center;
				background-size: 50%;
				background-repeat: no-repeat;
			}
		}
		.list-group-item.has-error {
			background-color: #f7e1e1;
		}
	}
}

.color-box {
	width: 18px;
	height: 18px;
	display: inline-block;
	vertical-align: text-bottom;
}

	.disabledText {
		color: grey;
	}

	.invalidRepetition {
		background-color: rgba(189,2,2,0.12);
	}

.table-container {
	min-height: 45px;
	overflow-y: auto;
	border: 1px solid #dedede;

  	.enrollment-name {
		word-break: break-all;
  	}
	.noBorder{
		border-bottom: 0px;
	}


	> table {
		margin-bottom: 0px;
	}

	th {
		overflow: hidden;
		white-space: nowrap;
	}

	.sort-pointer {
		cursor: pointer;
	}

	.arrow-up {
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;

		border-bottom: 5px solid black;
		font-size: 0;
		line-height: 0;
		margin-left: 4px;
		display:inline-block;
		vertical-align: middle;
	}

	.arrow-down {
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;


		border-top: 5px solid black;
		font-size: 0;
		line-height: 0;
		margin-left: 4px;
		display:inline;
	}

	.arrow-right {
		width: 0;
		height: 0;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;

		border-left: 5px solid black;
		font-size: 0;
		line-height: 0;
		margin-left: 4px;
		display:inline-block;
		vertical-align: middle;
	}

	.arrow-left {
		width: 0;
		height: 0;
		border-top: 5px solid transparent;
		border-bottom: 5px solid transparent;

		border-right:5px solid black;
		font-size: 0;
		line-height: 0;
		margin-left: 4px;
		display:inline-block;
		vertical-align: middle;
	}
}

.ews-indicator {
	width: 40px;
	height: 40px;
	border: 1px solid #797979;
	margin: 0 0 15px 0;
	text-align: center;
	padding-top: 8px;
	&.ews-green {
		background-color: #80c78b;
	}
	&.ews-yellow {
		background-color: #ffe655;
	}
	&.ews-red {
		background-color: #ff5353;
	}
}

.pull-right-abs { /* workaround for right alignment elements when inline-block positioning is used */
	position: absolute;
	right: 0;
}

/* fix for <ui-select> size/positioning in inline forms.
 careful - may affect tag select control */
.form-inline {
	.ui-select-container {
		display: inline-block;
		span.ui-select-toggle,
		input {
			width: 100%;
		}
	}
	> div {
		display: inline-block;
	}
}

.add-button {
	margin: 10px 10px 0;
	span {
		display: inline-block !important;
		background-image: url("../img/icons/icon_add_colorful.svg");
		background-size: 16px 16px;
		background-repeat: no-repeat;
		vertical-align: middle;
		width: 20px;
		height: 18px;
	}
}

.remove-button {
	float right
	background: transparent;
	background-image: url("../img/icons/icon_remove_colorful.svg");
	background-repeat: no-repeat;
	width: 20px;
	height: 20px;
	border: 0;
	padding: 0;
}

.vertical-choice {
	.radio label, .checkbox label {
		padding-top: 2px;
		padding-bottom: 2px;
	}
}

.medication-active {
	color: #9e9e9e !important;
}

/* fix for datepicker popup wrapper line break */
.toolbar-container {
	div[uib-datepicker-popup-wrap] {
		display: inline;
	}
}

/* too small screen info */
.sm-view {
	height: 100vh;
	background-color: #DFDFDF;
	text-align: center;
	p {
		padding: 150px 0 0 0;
		font-size: 20px;
		font-weight: bold;
	}
	@media (min-width: $screen-md-min) {
		display: none;
	}

	.sm-view {
	}
}

.hidden-on-mobile {
	display: none;
	@media (min-width: $screen-md-min) {
		display: block;
	}
}

a {
	&:focus, &:active {
		outline: none;
	}
}

.draft-element{
  font-style: italic;
  padding-right: 2px;
}

.control-label {
  .tooltip-icon-right {
	float: right;
	margin-right: 10px;
  }
}

.check-label {
	margin-left: 2px;
	margin-right: 4px;
	line-height: 8px;
}

.patient-centric-activity-list-table {
  width: 100%;
  border-collapse: separate;
}


.patient-centric-activity-list-table tr td.childrenName{
  padding-left: 30px !important;
}

.toggle-label-closed {
  display: block;
  width: 100%;
  height: 20px;
  overflow: hidden;
  margin: 0;
  background-image: url("../img/icons/arrowRight.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30% 60%;
  cursor: pointer;
}

.toggle-label-opened {
  display: block;
  width: 100%;
  height: 20px;
  overflow: hidden;
  margin: 0;
  background-image: url("../img/icons/arrowDown.svg");
  background-size: 60% 30%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.childrenName {
  padding-left: 30px;
}

.uib-datepicker .uib-day.highlight .btn.btn-default.btn-sm{
 background: #ececec;
}

.preserve-new-line {
  white-space: pre-line;
}

.needs-attention {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-size: contain;
  margin: 0 5px 0 5px;
  position: relative;
  background-repeat: no-repeat;
  background-image: url("../img/icons/Danger.svg");
}

.min-height-inherit {
  min-height: inherit
}

.tag {
  border: 1px solid #cccccc;
  padding: 0px 5px;
  border-radius: 3px;
  color: $default-text-color;
  background: #e6e6e6;
  position: relative;
  font-weight: normal;
  display: inline-block;
  font-size: small;
}

.preference-label-right-margin{
  margin-right: 15px;
}

.preference-label-left-margin{
  margin-left: 15px;
}

.default-color {
  color: $default-text-color !important;
}

.filter-select-min-width {
  min-width: 340px;
}

.program-pathway-additional-information {
  overflow: hidden;
  color: #9e9e9e;
  font-size:14px;
}

body.ipad {
  overflow-y: scroll;
  .modal-full {
	position: unset !important;
	.modal-container {
	  position: unset !important;
	}
	.patient-cards {
		height: 100% !important;
	}
  }
  div.modal[size="full"] + div.modal {
	display: none !important;
	position: relative !important;
  }

  div.modal[size="full"] + div + div.modal {
	display: none !important;
	position: relative !important;
  }
}

.underline-on-hover {
  cursor: pointer;
  &:hover {
	.row-label {
	  text-decoration: underline;
	}
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.image-button {
  &.btn {
	width: 26px;
	height: 36px;
	padding: 0px !important;
  }
  .img-wrapper {
	height: 100%;
	width: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	> i {
	  width: 16px;
	  height: 16px;
	}
  }
}

.buttons-container {
  display: inline-block;
}

.fs3-flower-chart-button {
  .img-wrapper {
	> i {
	  width: 20px;
	  height: 20px;
	  background-size: 20px 20px;
	  background-image: url("../img/icons/Hjul.svg");
	}
  }
}

.fs3-quick-filters-button {
  .img-wrapper {
	> i {
	  width: 20px;
	  height: 20px;
	  background-size: 20px 20px;
	  background-image: url("../img/icons/equalizer.svg");
	}
  }
}

.create-bulk-assignments-button {
  .img-wrapper {
	> i {
	  width: 20px;
	  height: 20px;
	  background-size: 20px 20px;
	  background-image: url("../img/icons/Check.svg");
	}
  }
}

.plus-button {
  span.caret-label-left, i {
    background-image: url("../img/icons/icon_add_colorful.svg");
	background-size: 16px 16px;
	background-repeat: no-repeat;
	vertical-align: middle;
  }
  span.caret-label-left {
	display: inline-block;
	text-indent: -9999px;
	width: 20px;
	height: 17px;
  }
}

.no-caret .caret {
  display: none;
}
